import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "react-bootstrap/esm/Feedback";
import checkNIF from "../../functions/checkNIF";
import { MiniLoader } from "../ui/MiniLoader";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_DATA_CLIENTE = "cliente";

export const EditarDatosFacturacion = () => {
  const [showOrdenFactura, setShowOrdenFactura] = useState(false);
  const [validated, setValidated] = useState(false);
  const [clientData, setClientData] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, nombre, apellido } = useSelector((state) => state.auth);
  const [editBlocked, setEditBlocked] = useState(false);
  const dispatch = useDispatch();

  const fetchClientData = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_DATA_CLIENTE, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          if (data.clinum !== "" && data.clinum !== 0) {
            setEditBlocked(true);
          }
          setClientData(data);
          document.getElementById("tipo-factura").value = data.periodo;
          showTipoFactura();
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchClientData();
  }, [fetchClientData]);

  const editClient = async (e) => {
    e.preventDefault();

    let btn = document.getElementById("btn-edit-data-facturacion");
    btn.disabled = true;

    let nombre = document.getElementById("nombre");
    let apellido = document.getElementById("apellidos");
    let nif = document.getElementById("dni");
    let dir = document.getElementById("direccion");
    let localidad = document.getElementById("localidad");
    let provincia = document.getElementById("provincia");
    let cp = document.getElementById("codigo-postal");
    let telefono = document.getElementById("telefono");
    let periodo = document.getElementById("tipo-factura");
    let factura = "No";
    let orden_factura;
    if (periodo !== "0") {
      orden_factura = document.getElementById("orden-factura");
      factura = "Si";
    }

    // Creo un objeto con los datos del cliente y su estado de validación
    let clientDataValidate = {
      nombre: false,
      apellido: false,
      nif: false,
      dir: false,
      localidad: false,
      provincia: false,
      cp: false,
      telefono: false,
      periodo: false,
      orden_factura: false,
    };

    // Validación de datos
    if (editBlocked) {
      if (nombre.value !== clientData.nombre) {
        nombre.setCustomValidity(" ");
        clientDataValidate.nombre = false;
      } else {
        nombre.setCustomValidity("");
        clientDataValidate.nombre = true;
      }
    } else {
      if (nombre.value === "" || nombre.value.length < 3) {
        nombre.setCustomValidity(" ");
        clientDataValidate.nombre = false;
      } else {
        nombre.setCustomValidity("");
        clientDataValidate.nombre = true;
      }
    }

    if (editBlocked) {
      if (apellido.value !== clientData.apellido) {
        apellido.setCustomValidity(" ");
        clientDataValidate.apellido = false;
      } else {
        apellido.setCustomValidity("");
        clientDataValidate.apellido = true;
      }
    } else {
      if (apellido.value === "" || apellido.value.length < 3) {
        apellido.setCustomValidity(" ");
        clientDataValidate.apellido = false;
      } else {
        apellido.setCustomValidity("");
        clientDataValidate.apellido = true;
      }
    }

    if (editBlocked) {
      if (nif.value !== clientData.nif) {
        nif.setCustomValidity(" ");
        clientDataValidate.nif = false;
      } else {
        nif.setCustomValidity("");
        clientDataValidate.nif = true;
      }
    } else {
      if (nif.value === "") {
        nif.setCustomValidity(" ");
        clientDataValidate.nif = false;
      } else if (checkNIF(nif.value) === false) {
        nif.setCustomValidity(" ");
        clientDataValidate.nif = false;
      } else {
        nif.setCustomValidity("");
        clientDataValidate.nif = true;
      }
    }

    if (dir.value === "" || dir.value.length < 3) {
      dir.setCustomValidity(" ");
      clientDataValidate.dir = false;
    } else {
      dir.setCustomValidity("");
      clientDataValidate.dir = true;
    }

    if (localidad.value === "" || localidad.value.length < 3) {
      localidad.setCustomValidity(" ");
      clientDataValidate.localidad = false;
    } else {
      localidad.setCustomValidity("");
      clientDataValidate.localidad = true;
    }

    if (provincia.value === "" || provincia.value.length < 3) {
      provincia.setCustomValidity(" ");
      clientDataValidate.provincia = false;
    } else {
      provincia.setCustomValidity("");
      clientDataValidate.provincia = true;
    }

    if (cp.value === "" || cp.value.length < 5 || cp.value.length > 5) {
      cp.setCustomValidity(" ");
      clientDataValidate.cp = false;
    } else {
      cp.setCustomValidity("");
      clientDataValidate.cp = true;
    }

    if (
      telefono.value === "" ||
      telefono.value.length != 9 ||
      isNaN(telefono.value) ||
      telefono.value.includes(" ")
    ) {
      telefono.setCustomValidity(" ");
      clientDataValidate.telefono = false;
    } else {
      telefono.setCustomValidity("");
      clientDataValidate.telefono = true;
    }

    if (periodo.value === "") {
      periodo.setCustomValidity(" ");
      clientDataValidate.periodo = false;
    } else {
      periodo.setCustomValidity("");
      clientDataValidate.periodo = true;
    }

    if (factura === "Si" && orden_factura?.value === "") {
      orden_factura.setCustomValidity(" ");
      clientDataValidate.orden_factura = false;
    } else {
      orden_factura?.setCustomValidity("");
      clientDataValidate.orden_factura = true;
    }

    setValidated(true);

    // Si todos los datos son válidos, se envían al servidor
    if (
      !clientDataValidate.nombre ||
      !clientDataValidate.apellido ||
      !clientDataValidate.nif ||
      !clientDataValidate.dir ||
      !clientDataValidate.localidad ||
      !clientDataValidate.provincia ||
      !clientDataValidate.cp ||
      !clientDataValidate.telefono ||
      !clientDataValidate.periodo ||
      !clientDataValidate.orden_factura
    ) {
      btn.disabled = false;
      return;
    }

    const data = {
      nombre: nombre.value,
      apellido: apellido.value,
      nif: nif.value,
      dir: dir.value,
      localidad: localidad.value,
      provincia: provincia.value,
      cp: cp.value,
      factura,
      periodo: periodo.value,
      orden_factura: orden_factura?.value,
      telefono: telefono.value,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(URL_GET_DATA_CLIENTE, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          toast(t("datos.facturacion.form.result-ok"), {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
          if (ValidateToken()) {
            navigate("/datos", {
              state: {
                editedFacturacion: true,
              },
            });
          } else {
            dispatch(startLogout("expired"));
          }
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      btn.disabled = false;
    }
  };

  const showTipoFactura = () => {
    let tipoFactura = document.getElementById("tipo-factura").value;
    if (tipoFactura === "1") {
      setShowOrdenFactura(true);
    } else {
      setShowOrdenFactura(false);
    }
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <h1>
              <Link to={`/datos`} className="no-decoration">
                &lt;
              </Link>
              {t("datos.facturacion.h1")}
            </h1>
          </Row>
        </Container>
        <Container>
          <Row className="content-section mb-4">
            <Form
              className="datos-facturacion"
              autoComplete="off"
              noValidate
              validated={validated}
              onSubmit={(e) => editClient(e)}
            >
              <Row>
                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.nombre")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="nombre"
                      placeholder={t("datos.facturacion.form.nombre")}
                      defaultValue={clientData?.nombre}
                      disabled={editBlocked}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.nombre.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.apellidos")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="apellidos"
                      placeholder={t("datos.facturacion.form.apellidos")}
                      defaultValue={clientData?.apellido}
                      disabled={editBlocked}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.apellidos.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.dni")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="dni"
                      placeholder={t("datos.facturacion.form.dni")}
                      defaultValue={clientData?.nif}
                      disabled={editBlocked}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.dni.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.direccion")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="direccion"
                      placeholder={t("datos.facturacion.form.direccion")}
                      defaultValue={clientData?.dir}
                      autoComplete="datos-facturacion-direccion"
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.direccion.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.localidad")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="localidad"
                      placeholder={t("datos.facturacion.form.localidad")}
                      defaultValue={clientData?.localidad}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.localidad.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.provincia")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="provincia"
                      placeholder={t("datos.facturacion.form.provincia")}
                      defaultValue={clientData?.provincia}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.provincia.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.cp")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="codigo-postal"
                      maxLength="5"
                      placeholder={t("datos.facturacion.form.cp")}
                      defaultValue={clientData?.cp}
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.cp.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.telefono")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      id="telefono"
                      maxLength="9"
                      placeholder={t("datos.facturacion.form.telefono")}
                      defaultValue={
                        clientData?.telefono !== "0" ? clientData?.telefono : ""
                      }
                    />
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.telefono.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.facturacion.form.tipo-factura")}
                    className="mb-3"
                  >
                    <Form.Select
                      aria-label="Tipo de factura"
                      id="tipo-factura"
                      placeholder={t("datos.facturacion.form.tipo-factura")}
                      onChange={showTipoFactura}
                      defaultValue={clientData?.periodo}
                    >
                      <option value="" disabled>
                        {t("datos.facturacion.form.tipo-factura.select")}
                      </option>
                      <option value="0">
                        {t("datos.facturacion.form.tipo-factura.sin-factura")}
                      </option>
                      <option value="1">
                        {t("datos.facturacion.form.tipo-factura.mensual")}
                      </option>
                      <option value="2">
                        {t("datos.facturacion.form.tipo-factura.instantanea")}
                      </option>
                    </Form.Select>
                    <Feedback type="invalid">
                      {t("datos.facturacion.form.tipo-factura.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                {showOrdenFactura && (
                  <Col sm={12} lg={6}>
                    <FloatingLabel
                      label={t("datos.facturacion.form.orden-factura")}
                      className="mb-3"
                    >
                      <Form.Select
                        aria-label="Orden de factura"
                        id="orden-factura"
                        placeholder={t("datos.facturacion.form.orden-factura")}
                        defaultValue={clientData?.orden_factura}
                      >
                        <option value="" disabled>
                          {t("datos.facturacion.form.orden-factura.select")}
                        </option>
                        <option value="Fecha">
                          {t("datos.facturacion.form.orden-factura.fecha")}
                        </option>
                        <option value="Matricula">
                          {t("datos.facturacion.form.orden-factura.matricula")}
                        </option>
                      </Form.Select>
                      <Feedback type="invalid">
                        {t("datos.facturacion.form.orden-factura.invalid")}
                      </Feedback>
                    </FloatingLabel>
                  </Col>
                )}
              </Row>

              <Row>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    id="btn-edit-data-facturacion"
                    variant="primary"
                    size="lg"
                    onClick={(e) => editClient(e)}
                  >
                    {t("datos.facturacion.form.save-btn")}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </Container>
    </>
  );
};
