import { Nav, Row } from "react-bootstrap";

export const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  recordsPerPage,
  totalRecords,
  setRecordsPerPage,
}) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const lastPage = () => setCurrentPage(nPages);
  const firstPage = () => setCurrentPage(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <Nav className="justify-content-between">
        <div>
          <Row>
            <span className="pagination-select-results">
              Mostrar{" "}
              <select
                className="form-select form-select-sm"
                onChange={(e) => {
                  setCurrentPage(1);
                  setRecordsPerPage(parseInt(e.target.value));
                }}
                defaultValue={recordsPerPage}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>{" "}
              registros
            </span>
          </Row>
          <Row>
            <span className="pagination-showing-text">
              Mostrando registros del{" "}
              <strong>
                {currentPage * recordsPerPage - recordsPerPage + 1}
              </strong>{" "}
              al{" "}
              <strong>
                {currentPage * recordsPerPage > totalRecords
                  ? totalRecords
                  : currentPage * recordsPerPage}
              </strong>{" "}
              de <strong>{totalRecords}</strong>
            </span>
          </Row>
        </div>
        <div>
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <span
                className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                onClick={currentPage === 1 ? null : firstPage}
              >
                <span className="material-icons">first_page</span>
              </span>
            </li>
            <li className="page-item">
              <span
                className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                onClick={currentPage === 1 ? null : prevPage}
              >
                <span className="material-icons">chevron_left</span>
              </span>
            </li>
            {pageNumbers.map(
              (pgNumber) =>
                pgNumber > currentPage - 2 &&
                pgNumber < currentPage + 2 && (
                  <li
                    key={pgNumber}
                    className={`page-item ${
                      currentPage === pgNumber ? "active" : ""
                    }`}
                  >
                    <span
                      onClick={() => setCurrentPage(pgNumber)}
                      className="page-link"
                    >
                      {pgNumber}
                    </span>
                  </li>
                )
            )}
            <li className="page-item">
              <span
                className={`page-link ${
                  currentPage === nPages ? "disabled" : ""
                }`}
                onClick={currentPage === nPages ? null : nextPage}
              >
                <span className="material-icons">chevron_right</span>
              </span>
            </li>
            <li className="page-item">
              <span
                className={`page-link ${
                  currentPage === nPages ? "disabled" : ""
                }`}
                onClick={currentPage === nPages ? null : lastPage}
              >
                <span className="material-icons">last_page</span>
              </span>
            </li>
          </ul>
        </div>
      </Nav>
    </>
  );
};
