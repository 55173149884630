import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { SelectAnios } from "../ui/filtros/SelectAnios";
import { SelectMeses } from "../ui/filtros/SelectMeses";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const FiltrosFacturas = ({
  url,
  token,
  facturas,
  setFacturas,
  loading,
  setLoading,
  setTerminoBusqueda,
  setFiltered,
}) => {
  const { t } = useTranslation();
  const { flotas, flotaActiva } = useSelector((state) => state.auth);
  const [hasFlotas, setHasFlotas] = useState(flotas);
  const [flotaVehiculos, setFlotaVehiculos] = useState(null);
  const dispatch = useDispatch();

  const monthNames = [
    t("repostajes.meses.enero"),
    t("repostajes.meses.febrero"),
    t("repostajes.meses.marzo"),
    t("repostajes.meses.abril"),
    t("repostajes.meses.mayo"),
    t("repostajes.meses.junio"),
    t("repostajes.meses.julio"),
    t("repostajes.meses.agosto"),
    t("repostajes.meses.septiembre"),
    t("repostajes.meses.octubre"),
    t("repostajes.meses.noviembre"),
    t("repostajes.meses.diciembre"),
  ];

  let today = new Date();
  let d;
  let monthList = [];
  let monthListActual = [];
  let monthListAnterior = [];
  let yearList = [];
  let monthYearList = [];

  // Obtenemos los meses que han transcurrido de este año
  for (let i = 0; i < today.getMonth() + 1; i++) {
    d = new Date(today.getFullYear(), i, 1);
    monthListActual[i] = [
      d.getFullYear(),
      monthNames[d.getMonth()],
      d.getMonth(),
    ];
    if (!yearList.includes(d.getFullYear())) {
      yearList.push(d.getFullYear());
    }
  }

  //Le damos la vuelta al array para que aparezca primero el mes actual
  monthListActual.reverse();

  // Obtenemos todos los meses del año anterior
  for (let i = 0; i < 12; i++) {
    d = new Date(today.getFullYear() - 1, i, 1);
    monthListAnterior.push([
      d.getFullYear(),
      monthNames[d.getMonth()],
      d.getMonth(),
    ]);
    if (!yearList.includes(d.getFullYear())) {
      yearList.push(d.getFullYear());
    }
  }

  monthListAnterior.reverse();

  // Unimos los dos arrays
  monthList = monthListActual.concat(monthListAnterior);

  const [year, setYear] = useState(yearList[0]);

  for (let i = 0; i < monthList.length; i++) {
    if (monthList[i][0] === year) {
      let mes = [];
      mes[0] = monthList[i][1];
      mes[1] = monthList[i][2];
      monthYearList.push(mes);
    }
  }

  const [month, setMonth] = useState(monthList[0][1]);

  const fetchFacturas = useCallback(
    async (id_cuenta = flotaActiva?.id_cuenta) => {
      let mes = 0;
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i][0] === month) {
          mes = monthYearList[i][1];
        }
      }

      let url_get_facturas = `${url}?mes=${mes}&anio=${year}`;
      if (hasFlotas != "0") {
        url_get_facturas = `${url_get_facturas}&id_cuenta=${id_cuenta}`;
      }

      try {
        setLoading(true);
        setFacturas([]);
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.get(url_get_facturas, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          });
          setLoading(false);
          setFacturas([]);
          setFiltered(false);
          setFlotaVehiculos(id_cuenta);
          if (response?.data?.code === 200) {
            let data = response.data.data;
            // Pasamos los datos al padre
            setFacturas(data);
          } else if (response?.data?.code === 404) {
            console.warn(response.data.message);
          } else {
            console.warn(response.data.message);
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    },
    [year, month]
  );

  useEffect(() => {
    // Comprobamos si en el año seleccionado se ha llegado al mes seleccionado, sino cambiamos al mes actual del año seleccionado
    let existe = false;
    if (monthYearList.length < 12) {
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i].includes(month)) {
          existe = true;
          break;
        }
      }
      if (!existe) {
        setMonth(monthYearList[0][0]);
      }
    }

    setYear(year);
    fetchFacturas();
  }, [year]);

  useEffect(() => {
    fetchFacturas();
    setMonth(month);
  }, [month]);

  useEffect(() => {
    if (hasFlotas && flotaActiva) {
      if (flotaVehiculos !== flotaActiva.id_cuenta) {
        setTerminoBusqueda("");
        fetchFacturas(flotaActiva?.id_cuenta);
      }
    }
  }, [flotaActiva, hasFlotas]);

  return (
    <>
      <SelectMeses items={monthYearList} actual={month} onChange={setMonth} />
      <SelectAnios items={yearList} actual={year} onChange={setYear} />
    </>
  );
};
