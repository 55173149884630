import loading_anim from "../../assets/images/loading_anim.gif";
import "./LoaderPetroprix.css";

export const MiniLoader = ({ size = "" }) => {
  let alto = "2em";
  let ancho = "2em";
  
  switch (size) {
    case "xs":
      alto = "1em";
      ancho = "1em";
      break;
    case "sm":
      alto = "2em";
      ancho = "2em";
      break;
    case "md":
      alto = "3em";
      ancho = "3em";
      break;
    case "lg":
      alto = "4em";
      ancho = "4em";
      break;
    case "xl":
      alto = "5em";
      ancho = "5em";
      break;
    default:
      alto = "2em";
      ancho = "2em";
      break;
  }


  return (
      <img
        src={loading_anim}
        alt="p"
        style={{ height: alto, width: ancho }}
      />
  );
};
