import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MiniLoader } from "../../MiniLoader";

export const SelectMatriculasMobile = (props) => {
  const { t } = useTranslation();
  const [matriculas, setMatriculas] = useState(props.items);
  const [filtrado, setFiltrado] = useState();

  const handleSelect = (e) => {
    // Recorremos todos los checkbox y comprobamos si están seleccionados
    // para añadirlos al array de matriculas seleccionadas
    let filtroMatriculas = [];
    matriculas.forEach((matricula) => {
      if (
        document.getElementById("checkbox-m-filter-matricula-" + matricula)
          .checked
      ) {
        filtroMatriculas.push(matricula);
      }
    });
    // Actualizamos el estado de las matriculas seleccionadas en el componente padre
    props.setActual(filtroMatriculas);
    props.setFiltroMatriculasActive(true);
  };

  useEffect(() => {
    setMatriculas(props.items);
    setFiltrado(props.actual);
  }, [props.items, props.actual]);

  return (
    <>
      <InputGroup className="filters-mobile-group mt-3" onClick={handleSelect}>
        <span className="filters-mobile-title">
          {t("repostajes.filtros.matriculas")}
        </span>
        {!props.loadingVehicles ? (
          matriculas.length > 0 ? (
            matriculas.map((matricula) => (
              <Form.Check
                type="checkbox"
                id={"checkbox-m-filter-matricula-" + matricula}
                key={"checkbox-m-filter-matricula-" + matricula}
                label={matricula}
                value={matricula}
                defaultChecked={
                  props.filtroMatriculasActive && props.actual.length > 0
                    ? props.actual.includes(matricula)
                    : true
                }
              />
            ))
          ) : (
            "-"
          )
        ) : (
          <MiniLoader />
        )}
      </InputGroup>
    </>
  );
};
