// Funcion para comprobar si la matricula es correcta haciendo una petición a la Lambda

import axiosApi from "../axios/axios-api";

export default async function checkMatricula(matricula, token) {
  const url = `checkplate?matricula=${matricula}`;

  if (process.env.REACT_APP_URL_API) {
    const response = await axiosApi.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (response?.data?.code === 200) {
      let data = {
        code: response.data.code,
        data: response.data.message,
      };
      return data;
    } else {
      let data = {
        code: response.data.code,
        data: response.data.message,
      };
      return data;
    }
  }
}
