import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../../store/auth";
import { ValidateToken } from "../../helpers/validateToken";

const URL_EDITAR_VEHICULO_FLOTA = "flotas/editarvehiculo/";
const URL_EDITAR_PIN_VEHICULO_FLOTA = "flotas/pin/";

export function ModalEditarVehiculo(props) {
  const {
    id_tar,
    id_tc,
    credito_definido,
    credito_consumido,
    matricula,
    factura,
    descripcion,
    max_operacion,
    max_imp_red,
    max_imp_gas,
    max_op_red,
    max_op_gas,
    max_op_30,
    tipo_vehiculo,
  } = props;
  const [pin, setPin] = useState(0);
  const [limiteCredito, setLimiteCredito] = useState();
  const [editTipoVehiculo, setEditTipoVehiculo] = useState();
  const [creditoDefinido, setCreditoDefinido] = useState();
  const [validated, setValidated] = useState(false);
  const [loadedInfo, setLoadedInfo] = useState(false);
  const { token } = useSelector((state) => state.auth);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setValidated(false);
    if (credito_definido === "Sin limite") {
      setLimiteCredito("-1");
      setCreditoDefinido(undefined);
    } else {
      setLimiteCredito("1");
      setCreditoDefinido(credito_definido?.replace(".", "").split(",")[0]);
    }
    setLoadedInfo(true);
  }, [credito_definido]);

  const editVehicle = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setValidated(false);

    // Recogemos los datos del formulario
    let descripcion = document.getElementById(
      "flotas-modal-editar-form-descripcion"
    );
    let factura = document.getElementById("flotas-modal-editar-form-factura");
    let pin_select = document.getElementById(
      "flotas-modal-editar-form-pin-select"
    );
    let pin_txt = document.getElementById("flotas-modal-editar-form-pin");
    let pin_txt_repeat = document.getElementById(
      "flotas-modal-editar-form-pin-repeat"
    );
    let limite_credito = document.getElementById(
      "flotas-modal-editar-form-limite-credito"
    );
    let max_operacion = document.getElementById(
      "flotas-modal-editar-form-impMaxRepost"
    );
    let max_imp_red = document.getElementById(
      "flotas-modal-editar-form-impMaxRepostPetroprix"
    );
    let max_imp_gas = document.getElementById(
      "flotas-modal-editar-form-impMaxDiaGas"
    );
    let max_op_red = document.getElementById(
      "flotas-modal-editar-form-numRepostDiaPetroprix"
    );
    let max_op_gas = document.getElementById(
      "flotas-modal-editar-form-numRepostDiaGas"
    );
    let max_op_30 = document.getElementById(
      "flotas-modal-editar-form-numRepostDia30Min"
    );

    let tipo_vehiculo = document.getElementById(
      "flotas-modal-editar-form-tipo-vehiculo"
    );

    let limite_mensual_value = "-1";

    let dataValid = {
      factura: false,
      pin_txt: false,
      pin_txt_repeat: false,
      limiteCredito: false,
      impMaxRepost: false,
      impMaxRepostPetroprix: false,
      impMaxDiaGas: false,
      numRepostDiaPetroprix: false,
      numRepostDiaGas: false,
      numRepostDia30Min: false,
      tipo_vehiculo: false,
    };

    if (factura.value === "") {
      factura.setCustomValidity(" ");
      dataValid.factura = false;
    } else {
      factura.setCustomValidity("");
      dataValid.factura = true;
    }

    if (pin_select.value === "1") {
      if (
        pin_txt.value === "" ||
        pin_txt.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity(" ");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pin_txt = false;
        dataValid.pin_txt_repeat = false;
      } else if (
        pin_txt_repeat.value === "" ||
        pin_txt_repeat.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity("");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pin_txt_repeat = false;
        dataValid.pin_txt = true;
      } else if (pin_txt.value !== pin_txt_repeat.value) {
        pin_txt_repeat.setCustomValidity(" ");
        pin_txt.setCustomValidity(" ");
        dataValid.pin_txt_repeat = false;
        dataValid.pin_txt = false;
      } else {
        pin_txt_repeat.setCustomValidity("");
        pin_txt.setCustomValidity("");
        dataValid.pin_txt_repeat = true;
        dataValid.pin_txt = true;
      }
    } else {
      dataValid.pin_txt_repeat = true;
      dataValid.pin_txt = true;
    }

    if (limite_credito.value === "1") {
      let limite_mensual_input = document.getElementById(
        "flotas-modal-editar-form-limite-mensual"
      );
      if (limite_mensual_input.value === "" || limite_mensual_input.value < 0) {
        limite_mensual_input.setCustomValidity(" ");
        dataValid.limiteCredito = false;
      } else {
        limite_mensual_value = limite_mensual_input.value;
        limite_mensual_input.setCustomValidity("");
        dataValid.limiteCredito = true;
      }
    } else {
      dataValid.limiteCredito = true;
    }

    if (max_operacion.value === "") {
      max_operacion.setCustomValidity(" ");
      dataValid.impMaxRepost = false;
    } else {
      max_operacion.setCustomValidity("");
      dataValid.impMaxRepost = true;
    }

    if (max_imp_red.value === "") {
      max_imp_red.setCustomValidity(" ");
      dataValid.impMaxRepostPetroprix = false;
    } else {
      max_imp_red.setCustomValidity("");
      dataValid.impMaxRepostPetroprix = true;
    }

    if (max_imp_gas.value === "") {
      max_imp_gas.setCustomValidity(" ");
      dataValid.impMaxDiaGas = false;
    } else {
      max_imp_gas.setCustomValidity("");
      dataValid.impMaxDiaGas = true;
    }

    if (max_op_red.value === "") {
      max_op_red.setCustomValidity(" ");
      dataValid.numRepostDiaPetroprix = false;
    } else {
      max_op_red.setCustomValidity("");
      dataValid.numRepostDiaPetroprix = true;
    }

    if (max_op_gas.value === "") {
      max_op_gas.setCustomValidity(" ");
      dataValid.numRepostDiaGas = false;
    } else {
      max_op_gas.setCustomValidity("");
      dataValid.numRepostDiaGas = true;
    }

    if (max_op_30.value === "") {
      max_op_30.setCustomValidity(" ");
      dataValid.numRepostDia30Min = false;
    } else {
      max_op_30.setCustomValidity("");
      dataValid.numRepostDia30Min = true;
    }

    if (tipo_vehiculo.value === "") {
      tipo_vehiculo.setCustomValidity(" ");
      dataValid.tipo_vehiculo = false;
    } else {
      tipo_vehiculo.setCustomValidity("");
      dataValid.tipo_vehiculo = true;
    }

    if (
      !dataValid.factura ||
      !dataValid.pin_txt ||
      !dataValid.pin_txt_repeat ||
      !dataValid.limiteCredito ||
      !dataValid.impMaxRepost ||
      !dataValid.impMaxRepostPetroprix ||
      !dataValid.impMaxDiaGas ||
      !dataValid.numRepostDiaPetroprix ||
      !dataValid.numRepostDiaGas ||
      !dataValid.numRepostDia30Min ||
      !dataValid.tipo_vehiculo
    ) {
      setValidated(true);
      return;
    } else {
      setValidated(true);

      let data = {
        IdTc: id_tc,
        credito_definido: limite_mensual_value,
        descripcion: descripcion.value,
        factura: factura.value,
        max_operacion: max_operacion.value,
        max_imp_red: max_imp_red.value,
        max_imp_gas: max_imp_gas.value,
        max_op_red: max_op_red.value,
        max_op_gas: max_op_gas.value,
        max_op_30: max_op_30.value,
        IdTar: id_tar,
        tipo_vehiculo: tipo_vehiculo.value,
      };

      try {
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.put(
            URL_EDITAR_VEHICULO_FLOTA + id_tc,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          if (response?.data?.code === 200) {
            if (pin_select.value === "1") {
              let dataPin = {
                new_pin: pin_txt.value,
                repeat_pin: pin_txt_repeat.value,
              };
              const responsePIN = await axiosApi.put(
                URL_EDITAR_PIN_VEHICULO_FLOTA + id_tc,
                dataPin,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
                }
              );
              if (responsePIN?.data?.code === 200) {
                props.onHide(true);
                toast(t("flotas.modal.editar.result.ok"), {
                  icon: <LogoPetroprix />,
                });
              } else {
                toast(t("flotas.modal.editar.result.ko.pin"), {
                  icon: <LogoPetroprix />,
                });
              }
            } else {
              props.onHide(true);
              toast(t("flotas.modal.editar.result.ok"), {
                icon: <LogoPetroprix />,
              });
            }
          } else {
            toast(t("flotas.modal.editar.result.ko"), {
              icon: <LogoPetroprix />,
            });
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-80w"
    >
      <Modal.Header>
        <Modal.Title>{t("flotas.modal.editar.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Form
            noValidate
            validated={validated}
            autoComplete="off"
            onSubmit={(e) => editVehicle(e)}
          >
            {/* Información no editable del vehiculo */}
            <Row>
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.matricula")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder={t("flotas.modal.editar.form.matricula")}
                    disabled
                    value={matricula}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={12} lg={3}>
                <FloatingLabel
                  controlId="floatingSelect"
                  label={t("flotas.modal.editar.form.tipo-vehiculo")}
                  className="mb-3"
                >
                  <Form.Select
                    aria-label="{t('flotas.modal.editar.form.tipo-vehiculo')}"
                    placeholder={t("flotas.modal.editar.form.tipo-vehiculo")}
                    id="flotas-modal-editar-form-tipo-vehiculo"
                    defaultValue={tipo_vehiculo}
                    onChange={(e) => setEditTipoVehiculo(e.target.value)}
                  >
                    <option value="" disabled>
                      {t("flotas.modal.editar.form.tipo-vehiculo.select")}
                    </option>
                    <option value="0">
                      {t("flotas.modal.editar.form.tipo-vehiculo.turismo")}
                    </option>
                    <option value="1">
                      {t("flotas.modal.editar.form.tipo-vehiculo.moto")}
                    </option>
                    <option value="2">
                      {t("flotas.modal.editar.form.tipo-vehiculo.camion")}
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.credito-usado")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder={t("flotas.modal.editar.form.credito-usado")}
                    disabled
                    value={`${credito_consumido} €`}
                  />
                </FloatingLabel>
              </Col>
              {/* Limite crédito */}
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.limite-credito")}
                  className="mb-3"
                >
                  <Form.Select
                    aria-label={t("flotas.modal.editar.form.limite-credito")}
                    placeholder={t("flotas.modal.editar.form.limite-credito")}
                    key={loadedInfo ? limiteCredito : ""}
                    defaultValue={limiteCredito}
                    id="flotas-modal-editar-form-limite-credito"
                    onChange={(e) => {
                      setLimiteCredito(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      {t(
                        "flotas.registro-vehiculo.step-1.form.limite-credito.select"
                      )}
                    </option>
                    <option value="-1">
                      {t("flotas.modal.editar.form.limite-credito.sin-limite")}
                    </option>
                    <option value="1">
                      {t("flotas.modal.editar.form.limite-credito.definir")}
                    </option>
                  </Form.Select>
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.limite-credito.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            {/* Etiqueta, Factura, PIN, Limite credito */}
            <Row>
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.descripcion")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-descripcion"
                    placeholder={t("flotas.modal.editar.form.descripcion")}
                    defaultValue={descripcion}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.factura")}
                  className="mb-3"
                >
                  <Form.Select
                    aria-label={t("flotas.modal.editar.form.factura.select")}
                    placeholder={t("flotas.modal.editar.form.factura.select")}
                    defaultValue={factura !== undefined ? factura : ""}
                    id="flotas-modal-editar-form-factura"
                  >
                    <option value="1">
                      {t("flotas.modal.editar.form.factura.si")}
                    </option>
                    <option value="0">
                      {t("flotas.modal.editar.form.factura.no")}
                    </option>
                  </Form.Select>
                  <Feedback type="invalid">
                    {t("flotas.modal.editar.form.validation.factura.invalid")}
                  </Feedback>
                </FloatingLabel>
              </Col>
              {/* PIN */}
              <Col sm={12} lg={3}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.pin")}
                  className="mb-3"
                >
                  <Form.Select
                    aria-label={t("flotas.modal.editar.form.pin")}
                    placeholder={t("flotas.modal.editar.form.pin")}
                    defaultValue="0"
                    id="flotas-modal-editar-form-pin-select"
                    onChange={(e) => setPin(e.target.value)}
                  >
                    <option value="" disabled>
                      {t("flotas.modal.editar.form.pin.select")}
                    </option>
                    <option value="0">
                      {t("flotas.modal.editar.form.pin.no")}
                    </option>
                    <option value="1">
                      {t("flotas.modal.editar.form.pin.si")}
                    </option>
                  </Form.Select>
                </FloatingLabel>
                {pin === "1" && (
                  <>
                    <FloatingLabel
                      label={t("flotas.modal.editar.form.pin-input")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        id="flotas-modal-editar-form-pin"
                        placeholder={t("flotas.modal.editar.form.pin-input")}
                        maxLength="4"
                        pattern="[0-9]{4}"
                        required
                        autoComplete="new-password"
                      />
                      <Feedback type="invalid">
                        {t("flotas.modal.editar.form.validation.pin.invalid")}
                      </Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      label={t("flotas.modal.editar.form.pin-repeat")}
                      className="mb-3"
                    >
                      <Form.Control
                        type="password"
                        id="flotas-modal-editar-form-pin-repeat"
                        placeholder={t("flotas.modal.editar.form.pin-repeat")}
                        maxLength="4"
                        pattern="[0-9]{4}"
                        required
                        autoComplete="new-password"
                      />
                      <Feedback type="invalid">
                        {t(
                          "flotas.modal.editar.form.validation.pin-confirm.invalid"
                        )}
                      </Feedback>
                    </FloatingLabel>
                  </>
                )}
              </Col>
              {limiteCredito === "1" && (
                <Col sm={12} lg={3}>
                  <FloatingLabel
                    label={t("flotas.modal.editar.form.limite.limite-mensual")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      id="flotas-modal-editar-form-limite-mensual"
                      placeholder={t(
                        "flotas.modal.editar.form.limite.limite-mensual"
                      )}
                      min="1"
                      defaultValue={creditoDefinido}
                    />
                    <Feedback type="invalid">
                      {t(
                        "flotas.modal.editar.form.validation.limite-mensual.invalid"
                      )}
                    </Feedback>
                  </FloatingLabel>
                </Col>
              )}
            </Row>

            {/* Importes maximos y nº repostajes diarios */}
            <Row className="mt-4">
              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.imp-max-repost")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-impMaxRepost"
                    placeholder={t("flotas.modal.editar.form.imp-max-repost")}
                    defaultValue={max_operacion}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.imp-max-repost.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>
              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.num-repost-dia-petroprix")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-numRepostDiaPetroprix"
                    placeholder={t(
                      "flotas.modal.editar.form.num-repost-dia-petroprix"
                    )}
                    defaultValue={max_op_red}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.num-repost-dia-petroprix.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>

              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.imp-max-repost-petroprix")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-impMaxRepostPetroprix"
                    placeholder={t(
                      "flotas.modal.editar.form.imp-max-repost-petroprix"
                    )}
                    defaultValue={max_imp_red}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.imp-max-repost-petroprix.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>

              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.num-repost-dia-gas")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-numRepostDiaGas"
                    placeholder={t(
                      "flotas.modal.editar.form.num-repost-dia-gas"
                    )}
                    defaultValue={max_op_gas}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.num-repost-dia-gas.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>

              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.imp-max-dia-gas")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-impMaxDiaGas"
                    placeholder={t("flotas.modal.editar.form.imp-max-dia-gas")}
                    defaultValue={max_imp_gas}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.imp-max-dia-gas.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>

              <Col sm={12} lg={6}>
                <FloatingLabel
                  label={t("flotas.modal.editar.form.num-repost-dia-30min")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="flotas-modal-editar-form-numRepostDia30Min"
                    placeholder={t(
                      "flotas.modal.editar.form.num-repost-dia-30min"
                    )}
                    defaultValue={max_op_30}
                  />
                  <Feedback type="invalid">
                    {t(
                      "flotas.modal.editar.form.validation.num-repost-dia-30min.invalid"
                    )}
                  </Feedback>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <div className="d-grid gap-2 mt-2 col-md-5 mx-auto">
                <Button
                  variant="outline-primary"
                  size="lg"
                  onClick={() => {
                    props.onHide(false);
                  }}
                >
                  {t("flotas.modal.editar.form.btn-cancel")}
                </Button>
                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  onClick={(e) => editVehicle(e)}
                >
                  {t("flotas.modal.editar.form.btn-guardar")}
                </Button>
              </div>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
