import React, { useState } from "react";
import { Form, Button, Row, FloatingLabel } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ValidateToken } from "../../../helpers/validateToken";
import { startLogout } from "../../../store/auth";

// creating functional component ans getting props from app.js and destucturing them
export const SaldoStep = ({ nextStep, handleFormData, values }) => {
  const [validated, setValidated] = useState(false);
  const { flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = async (e) => {
    e.preventDefault();
    setValidated(false);

    const importe = document.getElementById("flotas-agregar-saldo-importe");

    let dataValid = {
      flota: false,
      importe: false,
    };

    if (
      importe.value === "" ||
      importe.value < 1 ||
      isNaN(importe.value) ||
      importe.value > 3000
    ) {
      importe.setCustomValidity(" ");
      dataValid.importe = false;
    } else {
      importe.setCustomValidity("");
      dataValid.importe = true;
    }

    setValidated(true);

    if (!dataValid.importe) {
      return;
    } else {
      values.flota_id = flotaActiva.id_cuenta;
      values.flota_nombre = flotaActiva.nombre_cuenta;
      values.importe = importe.value;

      nextStep();
    }
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (ValidateToken()) {
      navigate("/flotas/mis-flotas");
    } else {
      dispatch(startLogout("expired"));
    }
  };

  return (
    <>
      <Form
        className="flotas-agregar-saldo-form"
        autoComplete="off"
        onSubmit={(e) => submitFormData(e)}
        noValidate
        validated={validated}
      >
        <Row>
          <FloatingLabel
            label={t("flotas.agregar-saldo.form.cuenta")}
            className="mb-3"
          >
            <Form.Control
              type="text"
              id="flotas-agregar-saldo-cuenta"
              placeholder={t("flotas.agregar-saldo.form.cuenta")}
              defaultValue={flotaActiva.nombre_cuenta}
              readOnly
            />
          </FloatingLabel>
          <FloatingLabel
            label={t("flotas.agregar-saldo.form.importe")}
            className="mb-3"
          >
            <Form.Control
              type="number"
              step="0.10"
              min="1"
              max="3000"
              defaultValue="1.00"
              placeholder="1.00"
              pattern="^\d*(\.\d{0,2})?$"
              id="flotas-agregar-saldo-importe"
            />
            <Feedback type="invalid">
              {t("flotas.agregar-saldo.form.importe.invalid")}
            </Feedback>
          </FloatingLabel>

          <Row>
            <div className="flotas-registro-buttons mt-5">
              <Button
                variant="outline-primary"
                size="lg"
                name="cantidad"
                onClick={handleGoBack}
              >
                {t("flotas.registro-flota.btn-back")}
              </Button>
              <Button variant="primary" size="lg" type="submit">
                {t("flotas.registro-flota.btn-next")}
              </Button>
            </div>
          </Row>
        </Row>
      </Form>
    </>
  );
};
