import React from "react";
import { useTranslation } from "react-i18next";
import info_icon from "../../../assets/images/info-icon.svg";

export const AlertBloqueaoPagos = () => {
  const { t } = useTranslation();

  return (
    <div className="alert-blocked" role="alert">
      <div className="alert-blocked-icon">
        <img src={info_icon} alt="info" className="info-img-icon-blocked" />
      </div>
      <div className="alert-blocked-text">
        <>
          <h6>{t("flotas.agregar-saldo.form.bloqueado.title")}</h6>
          <p>{t("flotas.agregar-saldo.form.bloqueado.content")}</p>
        </>
      </div>
    </div>
  );
};
