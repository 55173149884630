import { useState } from "react";
import { Table } from "react-bootstrap";
import { Pagination } from "../ui/Pagination";
import axiosApi from "../../axios/axios-api";
import { format } from "date-fns";
import { t } from "i18next";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { downloadPdf } from "../../helpers/downloadPdf";
import { useDispatch } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const TablaFacturas = ({ data, token, orderData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const dispatch = useDispatch();

  const nPages = Math.ceil(data.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  const URL_GET_DOWNLOAD_FACTURAS = "facturas/descargar";

  const downloadFile = async (archivo, num_factura) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(
          URL_GET_DOWNLOAD_FACTURAS + "?archivo=" + archivo,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          let data = response.data.data;
          downloadPdf(data, num_factura, "factura");
        } else if (response?.data?.code === 404) {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        } else {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      toast(t("facturas.form.table.download-error"), {
        icon: <LogoPetroprix />,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Table hover={false} className="facturas" borderless={true}>
        <thead>
          <tr>
            <th>
              {t("facturas.form.table.fecha-emision")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 0)} />
                <AiFillCaretUp onClick={orderData(true, 0)} />
              </span>
            </th>
            <th>
              {t("facturas.form.table.periodo")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 1)} />
                <AiFillCaretUp onClick={orderData(true, 1)} />
              </span>
            </th>
            <th>
              {t("facturas.form.table.importe")}{" "}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 2)} />
                <AiFillCaretUp onClick={orderData(true, 2)} />
              </span>
            </th>
            <th className="d-none d-lg-block">
              {t("facturas.form.table.factura")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 3)} />
                <AiFillCaretUp onClick={orderData(true, 3)} />
              </span>
            </th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.length > 0 ? (
            currentRecords.map((factura) => (
              <tr key={factura.id}>
                <td>{format(new Date(factura.fecha), "dd/MM/yyyy")}</td>
                <td>{factura.periodo}</td>
                <td>{factura.total} €</td>
                <td className="d-none d-lg-block">{factura.num_factura}</td>
                <td className="factura-pdf">
                  <span
                    className="factura-download"
                    onClick={() => {
                      downloadFile(factura.archivo, factura.num_factura);
                    }}
                  >
                    <span className="material-icons">file_download</span>
                    {/* {factura.archivo} */}
                    {t("facturas.form.table.descargar")}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: "center" }}>
                {t("facturas.form.table.no-results.search")}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordsPerPage={recordsPerPage}
        totalRecords={data.length}
        setRecordsPerPage={setRecordsPerPage}
      />
    </>
  );
};
