import React, { useEffect, useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const FlotaStepTwo = ({
  prevStep,
  nextStep,
  handleFormData,
  values,
}) => {
  //creating error state for validation
  const [error, setError] = useState(false);
  const [cantidad, setCantidad] = useState(0);

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    let cant = e.target.value;
    setCantidad(cant);
    values.cantidad = cant;
  };

  useEffect(() => {}, [cantidad]);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (values.cantidad === "") {
      setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <>
      {values.tipoPago === "Prepago" ||
      values.tipoPago === "Prepaido" ||
      values.tipoPago === "Prepaid" ? (
        <h1 className="flotas-registro centered">
          {t("flotas.registro-flota.step-2.h1.prepago")}
        </h1>
      ) : (
        <h1 className="flotas-registro centered">
          {t("flotas.registro-flota.step-2.h1.credito")}
        </h1>
      )}
      <Form
        className="flotas-registro-nueva mt-4 animate__animated animate__fadeIn"
        autoComplete="off"
        onSubmit={submitFormData}
      >
        <div className="flotas-registro-cantidad">
          <Form.Control
            type="number"
            className="flotas-input"
            value={values.cantidad > 0 && values.cantidad}
            min={0}
            onChange={handleSubmit}
          />
          {values.tipoPago === "Prepago" ||
          values.tipoPago === "Prepaido" ||
          values.tipoPago === "Prepaid" ? (
            <p className="flotas-registro-moneda">EUROS</p>
          ) : (
            <p className="flotas-registro-moneda">
              {t("flotas.registro-flota.step-2.moneda-mes")}
            </p>
          )}
        </div>
        <Row>
          <div className="flotas-registro-buttons mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              name="cantidad"
              onClick={prevStep}
            >
              {t("flotas.registro-flota.btn-back")}
            </Button>
            {values.cantidad !== "" && values.cantidad !== 0 ? (
              <Button variant="primary" size="lg" type="submit">
                {t("flotas.registro-flota.btn-next")}
              </Button>
            ) : (
              <Button variant="primary" size="lg" type="submit" disabled>
                {t("flotas.registro-flota.btn-next")}
              </Button>
            )}
          </div>
        </Row>
      </Form>
    </>
  );
};
