import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const SelectVehiculosMobile = (props) => {
  const { t } = useTranslation();
  const [vehiculos, setVehiculos] = useState(props.items);
  const [filtrado, setFiltrado] = useState();
  const [checkboxVehiculos, setCheckboxVehiculos] = useState([]);

  const handleSelect = (e) => {
    // Recorremos todos los checkbox y comprobamos si están seleccionados
    // para añadirlos al array de vehiculos seleccionados
    let filtroVehiculos = [];
    Object.entries(vehiculos).forEach(([id, vehiculo]) => {
      if (document.getElementById("checkbox-m-filter-vehiculo-" + id).checked) {
        filtroVehiculos.push(id);
      }
    });
    // Actualizamos el estado de los vehiculos seleccionados
    // Actualizamos el estado de los vehiculos seleccionados en el componente padre
    props.setActual(filtroVehiculos);
    props.setFiltroVehiculosActive(true);
  };

  useEffect(() => {
    setVehiculos(props.items);
    setFiltrado(props.actual);
  }, [props.items, props.actual]);

  return (
    <>
      <InputGroup className="filters-mobile-group mt-3" onClick={handleSelect}>
        <span className="filters-mobile-title">
          {t("repostajes.filtros.vehiculos")}
        </span>
        {Object.entries(vehiculos).map(([id, vehiculo]) => (
          <Form.Check
            type="checkbox"
            id={"checkbox-m-filter-vehiculo-" + id}
            key={"checkbox-m-filter-vehiculo-" + id}
            label={vehiculo}
            value={vehiculo}
            defaultChecked={
              props.filtroVehiculosActive
                ? props.actual.length > 0
                  ? props.actual.includes(id)
                  : false
                : true
            }
          />
        ))}
      </InputGroup>
    </>
  );
};
