import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";

export const SelectMeses = (props) => {
  const [selected, setSelected] = useState(props.actual);

  useEffect(() => {
    setSelected(props.actual);
    props.onChange(selected);
  }, [props.items, selected]);

  return (
    <>
      <DropdownButton
        variant={"filter-secondary"}
        title={selected}
        id={"dropdown-basic-button-meses"}
      >
        {props.items.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={() => {
              setSelected(item[0]);
            }}
          >
            <Form.Check
              type={"radio"}
              id={"opt-meses-" + item[0]}
              name={item[0]}
              label={item[0]}
              value={item[1]}
              className="filter-form"
              checked={selected === item[0]}
              readOnly
            />
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
};
