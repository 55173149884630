import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Button,
  Col,
  FloatingLabel,
  Row,
  InputGroup,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import axiosApi from "../../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../../ui/LogoPetroprix";
import { LoaderPetroprix } from "../../ui/LoaderPetroprix";
import { ValidateToken } from "../../../helpers/validateToken";
import { startLogout } from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { AlertBloqueaoPagos } from "./AlertBloqueoPagos";

const URL_GET_TARJETAS = "paycomet/tarjetas";
const URL_POST_CREAR_PAGO = "paycomet/payment";
const URL_POST_CREAR_PAGO_TARJETA = "paycomet/paymentCard";

export const ResumenStep = ({ handleFormData, prevStep, values, saldos }) => {
  const [validated, setValidated] = useState(false);
  const [urlIframe, setUrlIframe] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [loadingTarjetas, setLoadingTarjetas] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let saldoTotalNormalizado = saldos.saldoTotal.replace(".", "");
  saldoTotalNormalizado = saldoTotalNormalizado.replace(",", ".");

  let cuentaFinal = (
    parseFloat(saldoTotalNormalizado) + parseFloat(values.importe)
  ).toFixed(2);
  cuentaFinal = cuentaFinal.toString().replace(".", ",");
  cuentaFinal = cuentaFinal.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  let importeNormalizado = parseFloat(values.importe).toFixed(2);

  importeNormalizado = importeNormalizado.replace(".", ",");
  //ponemos el punto de los miles
  importeNormalizado = importeNormalizado.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const [listadoTarjetas, setListadoTarjetas] = useState([]);

  const fetchTarjetas = useCallback(async () => {
    setLoadingTarjetas(true);
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(`${URL_GET_TARJETAS}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setListadoTarjetas(data);
          setLoadingTarjetas(false);
        } else {
          setLoadingTarjetas(false);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoadingTarjetas(false);
    }
  }, []);

  useEffect(() => {
    fetchTarjetas();
  }, [fetchTarjetas]);

  // after form submit validating the form data using validator
  const aceptarPago = async (e) => {
    e.preventDefault();

    // Recuperamos la tarjeta seleccionada
    let tarjetaSeleccionada = document.querySelector(
      'input[name="principal"]:checked'
    )?.value;

    let btn_aceptar = document.getElementById(
      "flotas-agregar-saldo-btn-aceptar"
    );
    let btn_add = document.getElementById("flotas-agregar-saldo-btn-add");
    btn_aceptar.disabled = true;
    btn_add.disabled = true;

    // Creamos el objeto con los datos del vehículo
    const data = {
      idCcp: values.flota_id,
      id_tarjeta_paycomet: tarjetaSeleccionada,
      cantidad: values.importe,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(
          URL_POST_CREAR_PAGO_TARJETA,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          btn_aceptar.disabled = false;
          btn_add.disabled = false;
          window.open(response.data.data, "_self");
          return null;
        } else if (response?.data.code === 201) {
          navigate("/flotas/estado-operacion/ok");
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn_aceptar.disabled = false;
          btn_add.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      btn_aceptar.disabled = false;
      btn_add.disabled = false;
      console.error(error);
    }
  };

  const addMetodoPago = async (e) => {
    e.preventDefault();

    let btn_aceptar = document.getElementById(
      "flotas-agregar-saldo-btn-aceptar"
    );
    let btn_add = document.getElementById("flotas-agregar-saldo-btn-add");
    btn_aceptar.disabled = true;
    btn_add.disabled = true;

    // Creamos el objeto con los datos del vehículo
    const data = {
      idCcp: values.flota_id,
      cantidad: values.importe,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(URL_POST_CREAR_PAGO, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          btn_aceptar.disabled = false;
          btn_add.disabled = false;
          setUrlIframe(response.data.data);
          return null;
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn_aceptar.disabled = false;
          btn_add.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      btn_aceptar.disabled = false;
      btn_add.disabled = false;
      console.error(error);
    }
  };

  return (
    <>
      <Col sm={12} lg={6}>
        <Form
          className="flotas-agregar-saldo-form"
          autoComplete="off"
          onSubmit={(e) => aceptarPago(e)}
          noValidate
          validated={validated}
        >
          <Row>
            <FloatingLabel
              label={t("flotas.agregar-saldo.form.cuenta")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="flotas-agregar-saldo-cuenta"
                placeholder={t("flotas.agregar-saldo.form.cuenta")}
                defaultValue={values.flota_nombre}
                readOnly
              />
            </FloatingLabel>
            <FloatingLabel
              label={t("flotas.agregar-saldo.form.credito-actual")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder={t("flotas.agregar-saldo.form.credito-actual")}
                defaultValue={`${saldos.saldoTotal} €`}
                readOnly
                id="flotas-agregar-saldo-importe"
              />
              <Feedback type="invalid">
                {t("flotas.agregar-saldo.form.importe.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("flotas.agregar-saldo.form.cuenta-final")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder={t("flotas.agregar-saldo.form.cuenta-final")}
                defaultValue={`${cuentaFinal} €`}
                readOnly
                id="flotas-agregar-saldo-importe"
              />
            </FloatingLabel>

            <FloatingLabel
              label={t("flotas.agregar-saldo.form.total")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder={t("flotas.agregar-saldo.form.total")}
                defaultValue={`${importeNormalizado} €`}
                readOnly
                id="flotas-agregar-saldo-importe"
              />
            </FloatingLabel>
          </Row>
        </Form>
      </Col>
      {urlIframe === null ? (
        <Col sm={12} lg={6}>
          {!loadingTarjetas ? (
            <>
              {listadoTarjetas?.length > 0 ? (
                listadoTarjetas?.map((tarjeta, index) => (
                  <Row
                    key={index}
                    id={"tarjeta-" + tarjeta?.id_tarjeta_paycomet}
                  >
                    <div className="pagos-info-tarjeta">
                      <div className="pagos-default">
                        <InputGroup.Radio
                          name="principal"
                          value={tarjeta?.id_tarjeta_paycomet}
                          aria-label={t("pago.metodos.btn-favorita")}
                          className="datos-btn-favorito"
                          defaultChecked={
                            tarjeta?.principal === 1 ? true : false
                          }
                        />
                      </div>
                      <div className="pagos-datos">
                        <div className="pagos-datos-content">
                          <span className="pagos-tipo-tarjeta">
                            {tarjeta?.card_brand} {tarjeta?.card_type}
                          </span>
                          <span className="pagos-num-tarjeta">
                            {tarjeta?.pan}
                          </span>
                          {tarjeta?.expiracion !== null &&
                            tarjeta?.expiracion !== "" && (
                              <span className="pagos-caducidad">
                                {tarjeta?.expiracion?.substring(5, 7)}/
                                {tarjeta?.expiracion?.substring(2, 4)}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </Row>
                ))
              ) : (
                <div className="text-center">
                  <h6>{t("pago.metodos.no-result")}</h6>
                </div>
              )}
              <Col sm={8} lg={{ span: 6, offset: 3 }}>
                <Row>
                  <div className="d-grid gap-2 mt-5">
                    <Button
                      variant="outline-primary"
                      size="lg"
                      id="flotas-agregar-saldo-btn-add"
                      onClick={(e) => addMetodoPago(e)}
                    >
                      {t("flotas.agregar-saldo.btn.add")}
                    </Button>
                    <Button
                      variant="primary"
                      size="lg"
                      type="submit"
                      id="flotas-agregar-saldo-btn-aceptar"
                      disabled={listadoTarjetas?.length === 0 ? true : false}
                      onClick={(e) => aceptarPago(e)}
                    >
                      {t("flotas.agregar-saldo.btn.aceptar")}
                    </Button>
                  </div>
                </Row>
              </Col>
            </>
          ) : (
            <Row className="justify-content-center">
              <LoaderPetroprix />
            </Row>
          )}
        </Col>
      ) : (
        <Col sm={12} lg={{ span: 4, offset: 1 }}>
          <iframe
            src={urlIframe}
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            id="paycomet-iframe"
            title="paycomet-iframe"
          ></iframe>
        </Col>
      )}
    </>
  );
};
