import { useState } from "react";
import {
  Accordion,
  Button,
  Container,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import user_icon from "../../assets/images/user-icon.svg";
import invoice_icon from "../../assets/images/invoice-icon.svg";
import gasstation_icon from "../../assets/images/gasstation-icon.svg";
import promotion_icon from "../../assets/images/promotion-icon.svg";
import logout_icon from "../../assets/images/logout-icon.svg";
import fleets_icon from "../../assets/images/fleets-icon.svg";
import manual_icon from "../../assets/images/download-pdf-icon.svg";
import stats_icon from "../../assets/images/stats-icon.svg";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useTranslation } from "react-i18next";
import { FlotasSelection } from "../flotas/FlotasSelection";
import axiosApi from "../../axios/axios-api";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeFlota, startLogout } from "../../store/auth";
import { ModalCambiarPassword } from "./ModalCambiarPassword";
import { ValidateToken } from "../../helpers/validateToken";
import { downloadPdf } from "../../helpers/downloadPdf";
import { FlotasInfo } from "../flotas/FlotasInfo";

const URL_GET_DOWNLOAD_MANUAL = "flotas/descargarManual";

export const Menu = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { flotas, cuentas, nombre, apellido, flotaActiva, username, token } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const cuentaPersonal = cuentas.find((cuenta) => cuenta.personal === "1");

  const cambiaFlota = (flota, token) => {
    dispatch(changeFlota(flota, token));
  };

  const onLogout = () => {
    dispatch(startLogout());
  };

  const [modalPassword, setModalPassword] = useState({
    show: false,
    iduser: null,
  });

  const pathname = location.pathname.split("/");
  const ruta = pathname[pathname.length - 1];

  const downloadManual = async () => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_DOWNLOAD_MANUAL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          if (data.prepago !== null) {
            downloadPdf(data.prepago, "prepago", "Manual_flotas");
          }
          if (data.credito !== null) {
            downloadPdf(data.credito, "credito", "Manual_flotas");
          }
        } else if (response?.data?.code === 404) {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        } else {
          toast(t("facturas.form.table.download-error"), {
            icon: <LogoPetroprix />,
          });
          console.warn(response.data.message);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      toast(t("facturas.form.table.download-error"), {
        icon: <LogoPetroprix />,
      });
      console.error(error);
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="menu px-1 d-lg-none d-xl-none "
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top menu-logo-mobile"
              alt={t("menu.logo.text")}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar " />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            className="py-5 px-1 menu-offcanvas-content"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img
                  src={petroprix_logo}
                  className="d-inline-block align-top menu-logo-mobile"
                  alt={t("menu.logo.text")}
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Container className="container-menu-mobile">
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto mt-3 py-1 px-3">
                  <Nav.Link href="/datos">
                    <div className="row py-4">
                      <div className="col-2">
                        <img
                          src={user_icon}
                          className="menu-user-icon"
                          alt={t("menu.item.mis-datos")}
                        />
                      </div>
                      <div className="col-10">
                        <span className="px-4">{t("menu.item.mis-datos")}</span>
                      </div>
                    </div>
                  </Nav.Link>
                  <Nav.Link href="/facturas">
                    <div className="row py-4">
                      <div className="col-2">
                        <img
                          src={invoice_icon}
                          className="menu-facturas-icon"
                          alt={t("menu.item.facturas")}
                        />
                      </div>
                      <div className="col-10">
                        <span className="px-4">{t("menu.item.facturas")}</span>
                      </div>
                    </div>
                  </Nav.Link>
                  <Nav.Link href="/repostajes">
                    <div className="row py-4">
                      <div className="col-2">
                        <img
                          src={gasstation_icon}
                          className="menu-repostajes-icon"
                          alt={t("menu.item.repostajes")}
                        />
                      </div>
                      <div className="col-10">
                        <span className="px-4">
                          {t("menu.item.repostajes")}
                        </span>
                      </div>
                    </div>
                  </Nav.Link>
                  {/* <Nav.Link href="/estadisticas">
                  <div className="row py-4">
                    <div className="col-2">
                      <img src={stats_icon} className="menu-stats-icon" alt={t("menu.item.estadisticas")} />
                    </div>
                    <div className="col-10">
                      <span className="px-4">
                        {t("menu.item.estadisticas")}
                      </span>
                    </div>
                  </div>
                </Nav.Link> */}
                  <Nav.Link href="/promociones">
                    <div className="row py-4">
                      <div className="col-2">
                        <img
                          src={promotion_icon}
                          className="menu-promotion-icon"
                          alt="{t('menu.item.promociones')}"
                        />
                      </div>
                      <div className="col-10">
                        <span className="px-4">
                          {t("menu.item.promociones")}
                        </span>
                      </div>
                    </div>
                  </Nav.Link>
                  <Nav.Link href="/flotas/mis-flotas">
                    <div className="row py-4">
                      <div className="col-2">
                        <img
                          src={fleets_icon}
                          className="menu-flotas-icon"
                          alt="{t('menu.item.flotas')}"
                        />
                      </div>
                      <div className="col-10">
                        <span className="px-4">{t("menu.item.flotas")}</span>
                      </div>
                    </div>
                  </Nav.Link>
                  {flotas === "1" && (
                    <Container className="me-auto mt-3 py-1 px-5">
                      <div className="row py-2">
                        <p className="menu-manual-download">
                          {t("menu.item.manual.info")}
                        </p>
                        <Button
                          variant="outline-primary"
                          size="lg"
                          id="menu-manual-btn-download"
                          onClick={(e) => downloadManual(e)}
                          className="menu-manual-btn-download"
                        >
                          <img
                            src={manual_icon}
                            alt={t("menu.item.manual.icon")}
                          />
                          {t("menu.item.manual.button")}
                        </Button>
                      </div>
                    </Container>
                  )}
                </Nav>
              </Navbar.Collapse>
              <Accordion>
                <Accordion.Item eventKey="0" className="user-menu-mobile">
                  <Accordion.Header>
                    <div className="user-menu-mobile-title">
                      <div className="user-menu-mobile-username">
                        {nombre?.toLowerCase() + " " + apellido?.toLowerCase()}
                      </div>
                      <div className="user-menu-mobile-email">{username}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="user-menu-mobile-links">
                      {flotas === "1" && (
                        <>
                          {cuentaPersonal?.tipo_pago === "prepago" && (
                            <li>
                              <a href="/flotas/metodos-pago">
                                {t("header.user-menu.metodos-pago")}
                              </a>
                            </li>
                          )}
                          <li>
                            <a href="/flotas/historial">
                              {t("header.user-menu.historial")}
                            </a>
                          </li>
                        </>
                      )}
                      <li
                        onClick={() =>
                          setModalPassword({
                            show: true,
                            iduser: null,
                          })
                        }
                      >
                        {t("header.user-menu.password")}
                      </li>
                      <li onClick={onLogout} className="logout-item">
                        {t("header.user-menu.salir")}
                        <Button
                          variant="light"
                          className="user-exit"
                          onClick={onLogout}
                        >
                          <img
                            src={logout_icon}
                            alt={t("header.exit-icon.text")}
                          />
                        </Button>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Navbar.Offcanvas>
        </Container>
        {flotas === "1" &&
          ((location.pathname.startsWith("/flotas") &&
            ruta !== "historial" &&
            ruta !== "metodos-pago" &&
            ruta !== "nueva-tarjeta" &&
            ruta !== "nuevo-vehiculo") ||
            location.pathname.startsWith("/facturas") ||
            location.pathname.startsWith("/repostajes")) && (
            <>
              <FlotasSelection
                flotaActiva={flotaActiva}
                onChange={cambiaFlota}
                flotas={cuentas}
                token={token}
              />
            </>
          )}
      </Navbar>

      {/* Menu dispositivos grandes */}
      <Navbar className="d-none d-lg-block d-xl-block">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top menu-logo-mobile"
              alt={t("menu.logo.text")}
            />
          </Navbar.Brand>
          <h5 className="navbar-logo-footer">{t("menu.footer")}</h5>
          <Nav className="me-auto mt-3 py-1 px-3">
            <Nav.Link href="/datos">
              <div className="row py-2">
                <div className="col-2">
                  <img
                    src={user_icon}
                    className="menu-user-icon"
                    alt={t("menu.item.mis-datos")}
                  />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.mis-datos")}</span>
                </div>
              </div>
            </Nav.Link>
            <Nav.Link href="/facturas">
              <div className="row py-2">
                <div className="col-2">
                  <img
                    src={invoice_icon}
                    className="menu-facturas-icon"
                    alt={t("menu.item.facturas")}
                  />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.facturas")}</span>
                </div>
              </div>
            </Nav.Link>
            <Nav.Link href="/repostajes">
              <div className="row py-2">
                <div className="col-2">
                  <img
                    src={gasstation_icon}
                    className="menu-repostajes-icon"
                    alt={t("menu.item.repostajes")}
                  />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.repostajes")}</span>
                </div>
              </div>
            </Nav.Link>
            {/* <Nav.Link href="/estadisticas">
              <div className="row py-2">
                <div className="col-2">
                  <img src={stats_icon} className="menu-stats-icon" alt={t("menu.item.estadisticas")} />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.estadisticas")}</span>
                </div>
              </div>
            </Nav.Link> */}
            <Nav.Link href="/promociones">
              <div className="row py-2">
                <div className="col-2">
                  <img
                    src={promotion_icon}
                    className="menu-promotion-icon"
                    alt={t("menu.item.promociones")}
                  />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.promociones")}</span>
                </div>
              </div>
            </Nav.Link>
            <Nav.Link href="/flotas/mis-flotas">
              <div className="row py-2">
                <div className="col-2">
                  <img
                    src={fleets_icon}
                    className="menu-flotas-icon"
                    alt={t("menu.item.flotas")}
                  />
                </div>
                <div className="col-10">
                  <span className="px-4">{t("menu.item.flotas")}</span>
                </div>
              </div>
            </Nav.Link>
          </Nav>
        </Container>

        {flotas === "1" && (
          <Container className="me-auto mt-3 py-1 px-5">
            <div className="row py-2">
              <p className="menu-manual-download">
                {t("menu.item.manual.info")}
              </p>
              <Button
                variant="outline-primary"
                size="lg"
                id="menu-manual-btn-download"
                onClick={(e) => downloadManual(e)}
                className="menu-manual-btn-download"
              >
                <img src={manual_icon} alt={t("menu.item.manual.icon")} />
                {t("menu.item.manual.button")}
              </Button>
            </div>
          </Container>
        )}
      </Navbar>

      <ModalCambiarPassword
        show={modalPassword.show}
        onHide={() => {
          setModalPassword({ show: false });
        }}
        iduser={modalPassword.iduser}
      />
    </>
  );
};
