import React from "react";
import { useTranslation } from "react-i18next";
import info_icon from "../../assets/images/info-icon.svg";

export const AlertBloqueado = ({ bloqueada, flotas }) => {
  const { t } = useTranslation();

  return (
    <div className="alert-blocked" role="alert">
      <div className="alert-blocked-icon">
        <img src={info_icon} alt="info" className="info-img-icon-blocked" />
      </div>
      <div className="alert-blocked-text">
        {bloqueada.tipo === "deleted" ? (
          <>
            <h6>{t("datos.alert.bloqueado.limite.title.deleted")}</h6>
            <p>
              {t("datos.alert.bloqueado.limite.text.deleted-1")}
              <strong>{bloqueada.fecha_desbloqueo.replaceAll("-", "/")}</strong>
              {t("datos.alert.bloqueado.limite.text.deleted-2")}
              <strong>info@petroprix.com</strong>
              {flotas === "1" &&
                t("datos.alert.bloqueado.limite.text.deleted-3")}
            </p>
          </>
        ) : (
          <>
            <h6>{t("datos.alert.bloqueado.limite.title.limited")}</h6>
            <p>
              {t("datos.alert.bloqueado.limite.text.limited-1")}
              <strong>info@petroprix.com</strong>
              {t("datos.alert.bloqueado.limite.text.limited-2")}
              {flotas === "1" &&
                t("datos.alert.bloqueado.limite.text.limited-3")}
            </p>
          </>
        )}
      </div>
    </div>
  );
};
