import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import password_img from "../../assets/images/password-img.svg";
import "./login.css";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";

export const NewPasswordScreen = () => {
  const [validated, setValidated] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (event) => {
    const pass1 = document.getElementById("password");
    const pass2 = document.getElementById("password-repeat");

    event.preventDefault();
    event.stopPropagation();

    if (pass1.value.length < 6 || pass1.value.length > 9) {
      pass1.setCustomValidity(" ");
    } else {
      pass1.setCustomValidity("");
    }

    if (pass1.value !== pass2.value) {
      pass2.setCustomValidity(" ");
    } else {
      pass2.setCustomValidity("");
    }
    setValidated(true);
  };

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar collapseOnSelect expand="lg" className="menu px-1">
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <header className="d-none d-lg-block">
          <img
            src={petroprix_logo}
            className="d-inline-block align-left login-page-logo"
            alt={t("login.menu.text")}
          />
        </header>
        <Container className="login-container py-1">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={password_img} fluid="true" className="mt-5" />
            </Col>
            <Col xs={12} lg={{ span: 4, offset: 2, order: 3 }}>
              <h2 className="mt-5">{t("new-password.h2")}</h2>
              <Alert variant="primary-login">
                {t("new-password.alert")}
                <b>{t("new-password.alert.b")}</b>.
              </Alert>
              <Form
                noValidate
                validated={validated}
                className="login-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  label={t("new-password.form.password")}
                  className="mt-3"
                >
                  <Form.Control
                    type="password"
                    id="password"
                    placeholder={t("new-password.form.password")}
                    minLength="6"
                    maxLength="9"
                    required
                    autoComplete="new-password"
                  />
                </FloatingLabel>

                <FloatingLabel
                  label={t("new-password.form.password-repeat")}
                  className="mt-3"
                >
                  <Form.Control
                    type="password"
                    id="password-repeat"
                    placeholder={t("new-password.form.password-repeat")}
                    minLength="6"
                    maxLength="9"
                    required
                    autoComplete="new-password"
                  />
                  <Feedback type="invalid">
                    {t("new-password.form.feedback")}
                  </Feedback>
                </FloatingLabel>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="form-login-btn"
                  >
                    {t("new-password.form.btn-guardar")}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
