import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Header } from "../../ui/Header";
import { Menu } from "../../ui/Menu";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const RegistroVehiculoScreen = () => {
  const { state } = useLocation();
  const { token, nombre, apellido, cuentas, flotaActiva } = useSelector(
    (state) => state.auth
  );
  //state for steps
  const [step, setStep] = useState(1);

  const { t } = useTranslation();

  const cuentaPersonal = cuentas.find((cuenta) => cuenta.personal === "1");

  //state for form data
  const [formData, setFormData] = useState({
    flotaID: state ? cuentaPersonal.id_cuenta : "",
    flotaNombre: state ? cuentaPersonal.nombre_cuenta : "",
    matricula: state ? state.matricula : "",
    tipoVehiculo: state ? state.tipo_vehiculo : "",
    combustible: state ? state.tipo : "",
    factura: state ? state.factura : "",
    limiteCredito: "",
    cantidadLimite: "",
    pinTxt: "",
    pinTxtRepeat: "",
    impMaxRepost: "150",
    impMaxDiaPetroprix: "150",
    impMaxDiaGas: "150",
    numRepostDiaPetroprix: "3",
    numRepostDiaGas: "3",
    numRepostDia30Min: "3",
    descripcion: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  // // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form

    const { value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellido} />
            <Container>
              <Row className="page-header pb-4">
                <Col sm={12} lg={{ span: 5, order: 1 }}>
                  <h1>
                    <Link to={`/flotas/mis-flotas`} className="no-decoration">
                      &lt;
                    </Link>
                    {t("flotas.registro-vehiculo.step-1.header.h1")}
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="content-section mb-4">
                <StepOne
                  nextStep={nextStep}
                  values={formData}
                  flota={flotaActiva}
                  handleFormData={handleInputData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellido} />
            <Container>
              <Row className="page-header pb-4">
                <Col sm={12} lg={{ span: 5, order: 1 }}>
                  <h1>
                    <span onClick={prevStep} className="no-decoration">
                      &lt;
                    </span>
                    {t("flotas.registro-vehiculo.step-2.header.h1")}
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="content-section mb-4">
                <StepTwo
                  prevStep={prevStep}
                  values={formData}
                  handleFormData={handleInputData}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    default:
      return <></>;
  }
};
