// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-error-page {
  padding-top: 3.06em;
}

.error-page-logo {
  float: left;
  margin-top: 3.07em;
  width: 194px;
  height: 43px;
}

.error-section {
  margin-top: 5.12em;
}

.error-text {
  font-weight: 600;
}

.error-text-red {
  color: #db0b27;
}

@media (min-width: 992px) {
  .error-text {
    margin-top: 4em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feedback/error404.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".content-error-page {\r\n  padding-top: 3.06em;\r\n}\r\n\r\n.error-page-logo {\r\n  float: left;\r\n  margin-top: 3.07em;\r\n  width: 194px;\r\n  height: 43px;\r\n}\r\n\r\n.error-section {\r\n  margin-top: 5.12em;\r\n}\r\n\r\n.error-text {\r\n  font-weight: 600;\r\n}\r\n\r\n.error-text-red {\r\n  color: #db0b27;\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .error-text {\r\n    margin-top: 4em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
