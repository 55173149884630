import "./SwitchControlKm.css";

export const SwitchControlKm = ({ controlkm, setModalControlShow }) => {
  const handleChange = (event) => {
    setModalControlShow(true, !controlkm);
  };

  return (
    <label className={`switch ${controlkm ? "switch-checked" : ""}`}>
      <input
        type="checkbox"
        className="switch-input"
        checked={controlkm}
        onChange={(e) => handleChange(e)}
      />
      <span className="switch-label" data-on="KM OFF" data-off="KM ON"></span>
      <span className="switch-handle"></span>
    </label>
  );
};
