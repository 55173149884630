import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, authSlice.reducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
  },
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      inmutableCheck: {
        ignoredPaths: ["auth.flotaActiva"],
      },
    }),
});

export const persistor = persistStore(store);
