import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const SelectVehiculos = (props) => {
  const { t } = useTranslation();
  const [vehiculos, setVehiculos] = useState(props.items);
  const [filtrado, setFiltrado] = useState(vehiculos);

  const handleSelect = (e) => {
    e.preventDefault();
    let filtroVehiculos = [];
    let vehiculos = document.getElementById("filter-vehiculos").children;
    for (let i = 0; i < vehiculos.length; i++) {
      if (vehiculos[i].firstChild.firstChild.checked) {
        filtroVehiculos.push(vehiculos[i].firstChild.firstChild.value);
      }
    }
    props.onChange(filtroVehiculos);

    // Cerrar el dropdown al guardar los filtros
    document.getElementById("dropdown-autoclose-inside-vehiculos").click();
  };

  return (
    <>
      <Dropdown
        autoClose="outside"
        className="filter-secondary filter-vehiculo"
      >
        <Dropdown.Toggle
          id="dropdown-autoclose-inside-vehiculos"
          className="btn-filter-secondary"
          variant={"filter-secondary"}
        >
          {t("repostajes.filtros.vehiculos")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <span id="filter-vehiculos" className="filter-dropdown">
            {vehiculos.map((vehiculo, id) => (
              <Form.Group
                className="dropdown-item mb-0"
                controlId={"checkbox-filter-vehiculo-" + id}
                key={"checkbox-filter-vehiculo-" + id}
              >
                <Form.Check
                  type="checkbox"
                  label={vehiculo}
                  value={id}
                  defaultChecked={filtrado.includes(vehiculo)}
                />
              </Form.Group>
            ))}
          </span>
          <Dropdown.Item className="save-filters">
            <Button variant="secondary" onClick={handleSelect}>
              {t("repostajes.filtros.btn-guardar")}
            </Button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
