import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

export const FlotaStepThree = ({
  prevStep,
  nextStep,
  handleFormData,
  values,
}) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const [country, setCountry] = useState({
    pais: "ES",
    prefijo: "+34",
  });

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    if (
      values.nombre === "" ||
      values.apellidos === "" ||
      values.dni === "" ||
      values.direccion === "" ||
      values.localidad === "" ||
      values.provincia === "" ||
      values.codigoPostal === "" ||
      values.tipoFactura === "" ||
      values.telefono === ""
    ) {
      setError(true);
    } else {
      values.prefijo = country.prefijo;
      nextStep();
    }
  };

  const handleCountry = (e) => {
    let pref = e.target.name;
    switch (pref) {
      case "ES":
        setCountry({ pais: "ES", prefijo: "+34" });
        break;
      case "PT":
        setCountry({ pais: "PT", prefijo: "+351" });
        break;
      case "AR":
        setCountry({ pais: "AR", prefijo: "+54" });
        break;
      case "MX":
        setCountry({ pais: "MX", prefijo: "+52" });
        break;
      default:
        break;
    }
  };

  useEffect(() => {}, [country]);

  return (
    <>
      {/* LOS DATOS DE ESTE FORMULARIO SE RELLENARÁN CON LOS DATOS EL USUARIO QUE RECOGEREMOS DE LA API*/}
      <h1 className="flotas-registro">
        {t("flotas.registro-flota.step-3.h1")}
      </h1>
      <Form
        className="flotas-registro-nueva mt-4 animate__animated animate__fadeIn"
        autoComplete="off"
        onSubmit={submitFormData}
      >
        <Row>
          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.nombre")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="nombre"
                placeholder={t("flotas.registro-flota.step-3.form.nombre")}
                value={values.nombre}
                onChange={handleFormData("nombre")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.apellidos")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="apellidos"
                placeholder={t("flotas.registro-flota.step-3.form.apellidos")}
                value={values.apellidos}
                onChange={handleFormData("apellidos")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.dni")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="dni"
                placeholder={t("flotas.registro-flota.step-3.form.dni")}
                maxLength={9}
                minLength={9}
                value={values.dni}
                onChange={handleFormData("dni")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.direccion")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="direccion"
                placeholder={t("flotas.registro-flota.step-3.form.direccion")}
                value={values.direccion}
                onChange={handleFormData("direccion")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.localidad")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="localidad"
                placeholder={t("flotas.registro-flota.step-3.form.localidad")}
                value={values.localidad}
                onChange={handleFormData("localidad")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.provincia")}
              className="mb-3"
            >
              <Form.Select
                aria-label="Provincia"
                placeholder={t("flotas.registro-flota.step-3.form.provincia")}
                value={values.provincia}
                onChange={handleFormData("provincia")}
              >
                <option value="">Elige Provincia</option>
                <option value="Álava/Araba">Álava/Araba</option>
                <option value="Albacete">Albacete</option>
                <option value="Alicante">Alicante</option>
                <option value="Almería">Almería</option>
                <option value="Asturias">Asturias</option>
                <option value="Ávila">Ávila</option>
                <option value="Badajoz">Badajoz</option>
                <option value="Baleares">Baleares</option>
                <option value="Barcelona">Barcelona</option>
                <option value="Burgos">Burgos</option>
                <option value="Cáceres">Cáceres</option>
                <option value="Cádiz">Cádiz</option>
                <option value="Cantabria">Cantabria</option>
                <option value="Castellón">Castellón</option>
                <option value="Ceuta">Ceuta</option>
                <option value="Ciudad Real">Ciudad Real</option>
                <option value="Córdoba">Córdoba</option>
                <option value="Cuenca">Cuenca</option>
                <option value="Gerona/Girona">Gerona/Girona</option>
                <option value="Granada">Granada</option>
                <option value="Guadalajara">Guadalajara</option>
                <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                <option value="Huelva">Huelva</option>
                <option value="Huesca">Huesca</option>
                <option value="Jaén">Jaén</option>
                <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                <option value="La Rioja">La Rioja</option>
                <option value="Las Palmas">Las Palmas</option>
                <option value="León">León</option>
                <option value="Lérida/Lleida">Lérida/Lleida</option>
                <option value="Lugo">Lugo</option>
                <option value="Madrid">Madrid</option>
                <option value="Málaga">Málaga</option>
                <option value="Melilla">Melilla</option>
                <option value="Murcia">Murcia</option>
                <option value="Navarra">Navarra</option>
                <option value="Orense/Ourense">Orense/Ourense</option>
                <option value="Palencia">Palencia</option>
                <option value="Pontevedra">Pontevedra</option>
                <option value="Salamanca">Salamanca</option>
                <option value="Segovia">Segovia</option>
                <option value="Sevilla">Sevilla</option>
                <option value="Soria">Soria</option>
                <option value="Tarragona">Tarragona</option>
                <option value="Tenerife">Tenerife</option>
                <option value="Teruel">Teruel</option>
                <option value="Toledo">Toledo</option>
                <option value="Valencia">Valencia</option>
                <option value="Valladolid">Valladolid</option>
                <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                <option value="Zamora">Zamora</option>
                <option value="Zaragoza">Zaragoza</option>
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-3.form.cp")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="codigo-postal"
                maxLength={5}
                minLength={5}
                pattern="((\d{5}))"
                placeholder={t("flotas.registro-flota.step-3.form.cp")}
                onChange={handleFormData("codigoPostal")}
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              controlId="floatingSelect"
              label={t("flotas.registro-flota.step-3.form.tipo-factura")}
              className="mb-3"
            >
              <Form.Select
                aria-label="Provincia"
                placeholder={t(
                  "flotas.registro-flota.step-3.form.tipo-factura"
                )}
                value={values.tipoFactura}
                onChange={handleFormData("tipoFactura")}
              >
                <option>
                  {t("flotas.registro-flota.step-3.form.tipo-factura.select")}
                </option>
                <option value="1">
                  {t(
                    "flotas.registro-flota.step-3.form.tipo-factura.instantanea"
                  )}
                </option>
                <option value="2">
                  {t("flotas.registro-flota.step-3.form.tipo-factura.mensual")}
                </option>
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <Row>
              <Col xs={4} md={2}>
                <DropdownButton
                  key="up"
                  id="dropdown-button-drop-up"
                  className={`flotas-pref-tel flotas-pref-tel-${country.pais}`}
                  drop="up"
                  variant="flotas-tel"
                  title={country.prefijo}
                >
                  <Dropdown.Item onClick={handleCountry} name="ES">
                    <ReactCountryFlag countryCode="ES" svg />
                    {t("flotas.registro-flota.step-3.form.prefijo.esp")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleCountry} name="PT">
                    <ReactCountryFlag countryCode="PT" svg />
                    {t("flotas.registro-flota.step-3.form.prefijo.por")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleCountry} name="AR">
                    <ReactCountryFlag countryCode="AR" svg />
                    {t("flotas.registro-flota.step-3.form.prefijo.arg")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleCountry} name="MX">
                    <ReactCountryFlag countryCode="MX" svg />
                    {t("flotas.registro-flota.step-3.form.prefijo.mex")}
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
              <Col xs={8} md={{ span: 9, offset: 1 }}>
                <FloatingLabel
                  label={t("flotas.registro-flota.step-3.form.telefono")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    id="telefono"
                    placeholder={t(
                      "flotas.registro-flota.step-3.form.telefono"
                    )}
                    minLength={9}
                    maxLength={9}
                    pattern="((\d{9}))"
                    value={values.telefono}
                    onChange={handleFormData("telefono")}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={{ span: 6, offset: 3 }}>
            <Row>
              <div className="flotas-registro-buttons mt-3">
                <Button
                  variant="outline-primary"
                  size="lg"
                  name="facturacion"
                  onClick={prevStep}
                >
                  {t("flotas.registro-flota.btn-back")}
                </Button>
                <Button variant="primary" size="lg" type="submit">
                  {t("flotas.registro-flota.btn-next")}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};
