import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { changeKmFlota } from "../../store/auth";

const URL_PUT_CONTROL_KM = "flotas/kilometros/";

export function ModalInfoBloqueado(props) {
  const { show, onHide } = props;
  const { t } = useTranslation();

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => onHide({ show: false })}
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">{t("flotas.modal.bloqueado.h3")}</h3>
          <div className="d-grid gap-2  mt-2">
            <p>{t("flotas.modal.bloqueado.p")}</p>
          </div>
          <div className="d-grid gap-2  mt-5">
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                onHide({ show: false });
              }}
            >
              {t("flotas.modal.bloqueado.btn-cerrar")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
