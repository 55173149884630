import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import enTranslation from './en.json'
import esTranslation from './es.json'
import ptTranslation from './pt.json'

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  pt: {
    translation: ptTranslation,
  }
};
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'es',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    }
  });

  export default i18n;