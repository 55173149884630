import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const FlotaStepFive = ({
  prevStep,
  nextStep,
  handleFormData,
  values,
}) => {
  //creating error state for validation
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (
      ((values.tipoPago === "Prepago" ||
        values.tipoPago === "Prepaido" ||
        values.tipoPago === "Prepaid") &&
        values.nombreEmpresa === "") ||
      ((values.tipoPago === "Crédito" || values.tipoPago === "Credit") &&
        (values.nombreEmpresa === "" ||
          values.nombreTitularCuenta === "" ||
          values.numeroIban === "" ||
          values.codigoSwift === ""))
    ) {
      setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <>
      <h1 className="flotas-registro">
        {t("flotas.registro-flota.step-5.h1")}
      </h1>
      <Form
        className="flotas-registro-nueva mt-4 animate__animated animate__fadeIn"
        autoComplete="off"
        onSubmit={submitFormData}
      >
        <Row>
          <Col sm={12} lg={6}>
            <FloatingLabel
              controlId="floatingInput"
              label={t("flotas.registro-flota.step-5.form.nombre-empresa")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="&nbsp;"
                value={values.nombreEmpresa}
                onChange={handleFormData("nombreEmpresa")}
              />
            </FloatingLabel>
          </Col>
        </Row>
        {/* </Form.Group> */}
        {(values.tipoPago === "Crédito" || values.tipoPago === "Credit") && (
          <>
            <h1 className="flotas-registro mt-3">
              {t("flotas.registro-flota.step-5.form.h1")}
            </h1>
            <Row>
              <Col sm={12} lg={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("flotas.registro-flota.step-5.form.nombre-titular")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="&nbsp;"
                    value={values.nombreTitularCuenta}
                    onChange={handleFormData("nombreTitularCuenta")}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col sm={12} lg={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("flotas.registro-flota.step-5.form.iban")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="&nbsp;"
                    value={values.numeroIban}
                    onChange={handleFormData("numeroIban")}
                  />
                </FloatingLabel>
              </Col>

              <Col sm={12} lg={6}>
                <FloatingLabel
                  controlId="floatingInput"
                  label={t("flotas.registro-flota.step-5.form.swift")}
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="&nbsp;"
                    value={values.codigoSwift}
                    onChange={handleFormData("codigoSwift")}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </>
        )}

        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <Row>
            <div className="flotas-registro-buttons mt-3">
              <Button
                variant="outline-primary"
                size="lg"
                name="nombre-flota"
                onClick={prevStep}
              >
                {t("flotas.registro-flota.btn-back")}
              </Button>
              <Button variant="primary" size="lg" type="submit">
                {t("flotas.registro-flota.btn-next")}
              </Button>
            </div>
          </Row>
        </Col>
      </Form>
    </>
  );
};
