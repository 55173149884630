import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Image,
  Navbar,
  Row,
} from "react-bootstrap";
import petroprix_logo from "../../assets/images/petroprix-logo.svg";
import login_img from "../../assets/images/login-img.svg";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import Feedback from "react-bootstrap/esm/Feedback";
import "animate.css";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import ReCAPTCHA from "react-google-recaptcha";
import password_hide_icon from "../../assets/images/password-hide-icon.svg";
import password_show_icon from "../../assets/images/password-show-icon.svg";

const URL_REGISTRO = "cliente";

export const RegistroScreen = () => {
  const [validated, setValidated] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [errors, setErrors] = useState(false);
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
  const [captchaValid, setCaptchaValid] = useState(null);
  const captchaRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [iconShowPassword, setIconShowPassword] = useState(password_hide_icon);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [iconShowPasswordRepeat, setIconShowPasswordRepeat] =
    useState(password_hide_icon);
  const [messageFeedback, setMessageFeedback] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onChange = () => {
    if (captchaRef.current.getValue()) {
      setCaptchaValid(true);
    } else {
      setCaptchaValid(false);
    }
  };

  const handleSubmit = async (event) => {
    const pass = document.getElementById("password");
    const confirmPass = document.getElementById("password-repeat");
    const username = document.getElementById("email");

    event.preventDefault();
    event.stopPropagation();

    let dataValid = {
      username: false,
      pass: false,
      confirmPass: false,
    };

    if (validatedEmail(username.value) === false) {
      username.setCustomValidity(" ");
      dataValid.username = false;
    } else {
      username.setCustomValidity("");
      dataValid.username = true;
    }

    if (pass.value.length < 6 || pass.value.length > 9) {
      pass.setCustomValidity(" ");
      dataValid.pass = false;
    } else {
      pass.setCustomValidity("");
      dataValid.pass = true;
    }

    if (pass.value !== confirmPass.value) {
      confirmPass.setCustomValidity(" ");
      dataValid.confirmPass = false;
    } else {
      confirmPass.setCustomValidity("");
      dataValid.confirmPass = true;
    }

    if (!dataValid.username || !dataValid.pass || !dataValid.confirmPass) {
      setValidated(true);
      return;
    } else {
      setValidated(true);

      if (!captchaValid) {
        setCaptchaValid(false);
        return;
      }

      let data = {
        username: username.value.trim(),
        pass: pass.value.trim(),
        confirm_pass: confirmPass.value.trim(),
        captcha: captchaRef.current.getValue(),
        lugar_alta: "1",
      };

      try {
        if (process.env.REACT_APP_URL_API) {
          const response = await axiosApi.post(URL_REGISTRO, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response?.data?.code === 200) {
            setValidated(false);
            username.value = "";
            pass.value = "";
            confirmPass.value = "";

            setCompleted(true);
            setErrors(false);
            setMessageFeedback(response.data.message);
            //Navegamos a la pantalla de envio de email y pasamos por props el email
            navigate("/registro/email-enviado", {
              state: { email: data.username },
            });
          } else {
            setErrors(true);
            setCompleted(false);
            setMessageFeedback(response.data.message);
            console.warn(response.data.message);
            if (captchaRef.current) {
              captchaRef.current.reset();
            }
          }
        }
      } catch (error) {
        console.error(error);
        setValidated(false);
        setErrors(true);
        setCompleted(false);
        if (captchaRef.current) {
          captchaRef.current.reset();
        }
      }
    }
  };

  const validatedEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    showPassword
      ? setIconShowPassword(password_hide_icon)
      : setIconShowPassword(password_show_icon);
  };

  const handleShowPasswordRepeat = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
    showPasswordRepeat
      ? setIconShowPasswordRepeat(password_hide_icon)
      : setIconShowPasswordRepeat(password_show_icon);
  };

  useEffect(() => {
    return () => {
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
    };
  }, []);

  return (
    <>
      <Container className=" d-lg-none d-xl-none ">
        <Navbar collapseOnSelect expand="lg" className="menu px-1">
          <Navbar.Brand href="/">
            <img
              src={petroprix_logo}
              className="d-inline-block align-top"
              alt={t("login.menu.text")}
            />
          </Navbar.Brand>
        </Navbar>
      </Container>
      <Container className="content-login-page">
        <header className="d-none d-lg-block">
          <img
            src={petroprix_logo}
            className="d-inline-block align-left login-page-logo"
            alt={t("login.menu.text")}
          />
        </header>
        <Container className="login-container py-1">
          <Row className="content-section login-section">
            <Col xs={12} lg={{ span: 12, order: 1 }}>
              <h1>
                {t("login.header.h1")}
                <span className="red-text">{t("login.header.h1.red")}</span>
              </h1>
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 1, order: 3 }}
              className="d-none d-lg-block"
            >
              <Image src={login_img} fluid="true" className="mt-5" />
            </Col>
            <Col
              xs={12}
              lg={{ span: 4, offset: 2, order: 3 }}
              className="animate__animated animate__fadeInRight"
            >
              <h6 className="mt-5 go-back-link">
                <Link to={`/login`} className="no-decoration">
                  &lt; Volver
                </Link>{" "}
              </h6>
              <h2>{t("register.h2")}</h2>
              {completed && !errors && (
                <Alert variant="success-login">{messageFeedback}</Alert>
              )}

              {!completed && !errors && (
                <Alert variant="primary-login">
                  {t("register.alert")}
                  <b>{t("register.alert.b-2")}</b> {t("register.alert-2")}{" "}
                  <b>{t("register.alert.b-3")}</b>
                </Alert>
              )}
              {errors && !completed && (
                <Alert variant="danger-login">{messageFeedback}</Alert>
              )}

              {captchaValid === false && (
                <Alert variant="danger-login">
                  <b>{t("login.form.captcha.not-valid")}</b>
                </Alert>
              )}
              <Form
                noValidate
                validated={validated}
                className="login-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <FloatingLabel
                  label={t("register.form.email")}
                  className="mt-3"
                >
                  <Form.Control
                    type="email"
                    id="email"
                    placeholder={t("register.form.email")}
                    required
                  />
                  <Feedback type="invalid">
                    {t("register.form.email.invalid")}
                  </Feedback>
                </FloatingLabel>

                <FloatingLabel
                  label={t("register.form.password")}
                  className="mt-3"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder={t("register.form.password")}
                    minLength="6"
                    maxLength="9"
                    required
                    autoComplete="new-password"
                  />
                  <span
                    toggle="#password-field"
                    className="toggle-password"
                    onClick={handleShowPassword}
                  >
                    <img src={iconShowPassword} className="passwordIcon" />
                  </span>
                  <Feedback type="invalid">
                    {t("register.form.password.invalid")}
                  </Feedback>
                </FloatingLabel>

                <FloatingLabel
                  label={t("register.form.password-repeat")}
                  className="mt-3"
                >
                  <Form.Control
                    type={showPasswordRepeat ? "text" : "password"}
                    id="password-repeat"
                    placeholder={t("register.form.password-repeat")}
                    minLength="6"
                    maxLength="9"
                    required
                    autoComplete="new-password"
                  />
                  <span
                    toggle="#password-field"
                    className="toggle-password"
                    onClick={handleShowPasswordRepeat}
                  >
                    <img
                      src={iconShowPasswordRepeat}
                      className="passwordIcon"
                    />
                  </span>
                  <Feedback type="invalid">
                    {t("register.form.password-repeat.invalid")}
                  </Feedback>
                </FloatingLabel>

                <div className="d-flex mt-3 justify-content-center recaptcha">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={SITE_KEY}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className="form-login-btn"
                  >
                    {t("register.form.btn-crear")}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
