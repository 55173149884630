import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import axiosApi from "../../axios/axios-api";
import { useTranslation } from "react-i18next";
import { SelectMatriculasMobile } from "../ui/filtros/mobile/SelectMatriculasMobile";
import { SelectVehiculosMobile } from "../ui/filtros/mobile/SelectVehiculosMobile";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export const FiltrosRepostajesMobile = ({
  url,
  token,
  matriculas,
  vehiculos,
  setRepostajes,
  setLoading,
  loadingVehicles,
  setLoadingVehicles,
  setMatriculas,
  setTerminoBusqueda,
  setFiltered,
  setResultadosFiltrados,
}) => {
  const { t } = useTranslation();
  const { flotas, flotaActiva } = useSelector((state) => state.auth);
  const [hasFlotas, setHasFlotas] = useState(flotas);
  const dispatch = useDispatch();

  const monthNames = [
    t("repostajes.meses.enero"),
    t("repostajes.meses.febrero"),
    t("repostajes.meses.marzo"),
    t("repostajes.meses.abril"),
    t("repostajes.meses.mayo"),
    t("repostajes.meses.junio"),
    t("repostajes.meses.julio"),
    t("repostajes.meses.agosto"),
    t("repostajes.meses.septiembre"),
    t("repostajes.meses.octubre"),
    t("repostajes.meses.noviembre"),
    t("repostajes.meses.diciembre"),
  ];

  let today = new Date();
  let d;
  let monthList = [];
  let monthListActual = [];
  let monthListAnterior = [];
  let yearList = [];
  let monthYearList = [];

  // Obtenemos los meses que han transcurrido de este año
  for (let i = 0; i < today.getMonth() + 1; i++) {
    d = new Date(today.getFullYear(), i, 1);
    monthListActual[i] = [
      d.getFullYear(),
      monthNames[d.getMonth()],
      d.getMonth(),
    ];
    if (!yearList.includes(d.getFullYear())) {
      yearList.push(d.getFullYear());
    }
  }

  //Le damos la vuelta al array para que aparezca primero el mes actual
  monthListActual.reverse();

  // Obtenemos todos los meses del año anterior
  for (let i = 0; i < 12; i++) {
    d = new Date(today.getFullYear() - 1, i, 1);
    monthListAnterior.push([
      d.getFullYear(),
      monthNames[d.getMonth()],
      d.getMonth(),
    ]);
    if (!yearList.includes(d.getFullYear())) {
      yearList.push(d.getFullYear());
    }
  }

  monthListAnterior.reverse();

  // Unimos los dos arrays
  monthList = monthListActual.concat(monthListAnterior);

  const [year, setYear] = useState(yearList[0]);

  for (let i = 0; i < monthList.length; i++) {
    if (monthList[i][0] == year) {
      let mes = [];
      mes[0] = monthList[i][1];
      mes[1] = monthList[i][2];
      monthYearList.push(mes);
    }
  }

  const [month, setMonth] = useState(monthList[0][1]);
  const [filtroMatriculas, setFiltroMatriculas] = useState();
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const [filtroMatriculasActive, setFiltroMatriculasActive] = useState(false);
  const [filtroVehiculosActive, setFiltroVehiculosActive] = useState(false);
  const [filtroVehiculos, setFiltroVehiculos] = useState(["0", "1", "2"]);
  const [itemsVehiculos, setItemsVehiculos] = useState({
    0: vehiculos[0],
    1: vehiculos[1],
    2: vehiculos[2],
  });
  const [repostajesTotal, setRepostajesTotal] = useState([]);

  useEffect(() => {
    // setMonth(parseInt(monthYearList[0][1]));
    // Comprobamos si en el año seleccionado se ha llegado al mes seleccionado, sino cambiamos al mes actual del año seleccionado
    let existe = false;
    if (monthYearList.length > 0 && monthYearList.length < 12) {
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i].includes(month)) {
          existe = true;
          break;
        }
      }
      if (!existe) {
        setMonth(monthYearList[0][0]);
      }
    } else {
      setMonth(month);
    }
    setYear(year);
    fetchRepostajes(
      filtroMatriculas,
      filtroVehiculos,
      filtroMatriculasActive,
      false,
      true
    );
  }, [year]);

  useEffect(() => {
    setMonth(month);
    fetchRepostajes(
      filtroMatriculas,
      filtroVehiculos,
      filtroMatriculasActive,
      false,
      true
    );
  }, [month]);

  const handleChange = ({ target }) => {
    switch (target.name) {
      case "month":
        setMonth(target.value);
        break;
      case "year":
        setYear(target.value);
        break;
      default:
        break;
    }
  };

  const handleSaveFilters = () => {
    fetchRepostajes(filtroMatriculas, filtroVehiculos, filtroMatriculasActive);
  };

  const fetchRepostajes = useCallback(
    async (
      filtroMatriculas,
      filtroVehiculos,
      filtroMatriculasActive,
      filter = true,
      listadoMatriculas = false,
      id_cuenta = flotaActiva?.id_cuenta
    ) => {
      if (listadoMatriculas) {
        setLoadingVehicles(true);
      }

      let mes = 0;
      for (let i = 0; i < monthYearList.length; i++) {
        if (monthYearList[i][0] === month) {
          mes = monthYearList[i][1];
        }
      }

      let url_get_repostajes = `${url}?mes=${mes}&anio=${year}`;
      if (hasFlotas != "0") {
        url_get_repostajes = `${url_get_repostajes}&id_cuenta=${id_cuenta}`;
      }

      try {
        setLoading(true);
        setRepostajes([]);
        setRepostajesTotal([]);

        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.get(url_get_repostajes, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          });
          setLoading(false);
          if (listadoMatriculas) {
            setLoadingVehicles(false);
          }
          setRepostajes([]);
          setRepostajesTotal([]);
          setFiltered(false);
          if (response?.data?.code === 200) {
            let data = response.data.data;
            // Pasamos los datos al padre
            setRepostajes(data.repostajes);
            setRepostajesTotal(data.repostajes);
            setMatriculas(data.matriculas);
            if (filter) {
              handleFilterRepostajes(
                filtroMatriculas,
                filtroVehiculos,
                filtroMatriculasActive,
                data.repostajes,
                filter,
                data.matriculas
              );
            }
          } else if (response?.data?.code === 404) {
            console.warn(response.data.message);
            handleFilterRepostajes(
              filtroMatriculas,
              filtroVehiculos,
              filtroMatriculasActive,
              [],
              filter,
              []
            );
          } else {
            console.warn(response.data.message);
            handleFilterRepostajes(
              filtroMatriculas,
              filtroVehiculos,
              filtroMatriculasActive,
              [],
              filter,
              []
            );
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        if (filter) {
          let closeFiltersBtn = document.getElementById("closeFiltersBtn");
          let closeFiltersBtnChild =
            closeFiltersBtn.getElementsByTagName("button");
          closeFiltersBtnChild[0].click();
        }
      }
    },
    [year, month]
  );

  const handleFilterRepostajes = (
    filtroMatriculas,
    filtroVehiculos,
    filtroMatriculaActive,
    repostajesTotal,
    filter,
    matriculasTotal
  ) => {
    let results_temp = [];
    let results = [];
    let filtroMatricula = false;
    let filtroVehiculo = false;
    if (filter) {
      // Filtramos en primer lugar por vehiculos si hay alguno seleccionado
      if (filtroVehiculos.length < 3) {
        for (let i = 0; i < filtroVehiculos.length; i++) {
          for (let j = 0; j < repostajesTotal.length; j++) {
            if (repostajesTotal[j].tipo_vehiculo == filtroVehiculos[i]) {
              results.push(repostajesTotal[j]);
            }
          }
        }

        filtroVehiculo = true;
      } else {
        results = [...repostajesTotal];
      }

      // Filtramos en segundo lugar por matriculas si hay alguna seleccionada
      if (
        filtroMatriculas?.length > 0 &&
        filtroMatriculas?.length < matriculasTotal?.length
      ) {
        for (let i = 0; i < filtroMatriculas.length; i++) {
          for (let j = 0; j < results.length; j++) {
            if (
              results[j].matricula.toLowerCase().trim() ===
              filtroMatriculas[i].toLowerCase().trim()
            ) {
              results_temp.push(results[j]);
            }
          }
        }
        results = [...results_temp];
        filtroMatricula = true;
      }
    }
    // Actualizamos el estado de los repostajes
    setFiltered(true);
    setResultadosFiltrados(results);
    setRepostajesTotal(repostajesTotal);

    let closeFiltersBtn = document.getElementById("closeFiltersBtn");
    let closeFiltersBtnChild = closeFiltersBtn.getElementsByTagName("button");
    closeFiltersBtnChild[0].click();
  };

  useEffect(() => {}, [filtroMatriculas]);

  useEffect(() => {
    if (hasFlotas) {
      fetchRepostajes(
        filtroMatriculas,
        filtroVehiculos,
        filtroMatriculasActive,
        false,
        true,
        flotaActiva?.id_cuenta
      );
    }
  }, [flotaActiva, hasFlotas]);

  return (
    <>
      <Navbar collapseOnSelect expand="sm" className="filters-mobile">
        <Navbar.Toggle aria-controls="offcanvasNavbar " />
        <Navbar.Offcanvas
          id="offcanvasNavbarFilters"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
          className="px-1"
        >
          <Offcanvas.Header closeButton id="closeFiltersBtn">
            <h1>{t("repostajes.h1")}</h1>
          </Offcanvas.Header>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto px-3 filters-mobile-nav">
              <Form className="filters-mobile-form">
                <SelectMatriculasMobile
                  items={matriculas}
                  actual={filtroMatriculas}
                  setActual={setFiltroMatriculas}
                  loadingVehicles={loadingVehicles}
                  filtroMatriculasActive={filtroMatriculasActive}
                  setFiltroMatriculasActive={setFiltroMatriculasActive}
                />
                <SelectVehiculosMobile
                  items={itemsVehiculos}
                  actual={filtroVehiculos}
                  setFiltroVehiculos={setFiltroVehiculos}
                  setActual={setFiltroVehiculos}
                  filtroVehiculosActive={filtroVehiculosActive}
                  setFiltroVehiculosActive={setFiltroVehiculosActive}
                />
                <InputGroup className="filters-mobile-group-fechas mt-3">
                  <span className="filters-mobile-title">
                    {t("repostajes.filtros.fecha")}
                  </span>
                  <Form.Select
                    aria-label="Default select example"
                    name="month"
                    onChange={handleChange}
                    defaultValue={month}
                  >
                    {monthYearList.map((mes) => (
                      <option value={mes[0]} key={mes[0]}>
                        {mes[0]}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Select
                    aria-label="Default select example"
                    name="year"
                    onChange={handleChange}
                    defaultValue={year}
                  >
                    {yearList.map((anio) => (
                      <option value={anio} key={anio}>
                        {anio}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                <InputGroup className="filters-mobile-group-btn">
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={handleSaveFilters}
                  >
                    {t("repostajes.filtros.btn-guardar")}
                  </Button>
                </InputGroup>
              </Form>
            </Nav>
          </Navbar.Collapse>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
};
