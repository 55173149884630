import { Routes, Route, Navigate } from "react-router-dom";
import { LoginScreen } from "../components/login/LoginScreen";
import { RegistroScreen } from "../components/login/RegistroScreen";
import { RecoveryScreen } from "../components/login/RecoveryScreen";
import { NewPasswordScreen } from "../components/login/NewPasswordScreen";
import { Error404Screen } from "../components/feedback/Error404Screen";
import { VerifyEmailScreen } from "../components/login/VerifyEmailScreen";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/registro" element={<RegistroScreen />} />
      <Route path="/registro/email-enviado" element={<VerifyEmailScreen />} />
      <Route path="/recuperar-password" element={<RecoveryScreen />} />
      <Route path="/new-password" element={<NewPasswordScreen />} />

      <Route path="/404" element={<Error404Screen />} />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
