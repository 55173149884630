import { Alert, Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const NotificacionesScreen = () => {
  const { t } = useTranslation();
  const { token, nombre, apellido } = useSelector((state) => state.auth);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col sm={12} lg={{ span: 5, order: 1 }}>
              <h1>
                <Link to={`/flotas/mis-flotas`} className="no-decoration">
                  &lt;
                </Link>
                {t("flotas.notificaciones.h1")}
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="content-section">
            <Table className="facturas" borderless={true} hover={false}>
              <thead>
                <tr>
                  <th>{t("flotas.notificaciones.table.fecha")}</th>
                  <th>{t("flotas.notificaciones.table.tipo")}</th>
                  <th>{t("flotas.notificaciones.table.descripcion")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="facturas-fecha">15/05/21</td>
                  <td className="facturas-periodo">
                    <Alert variant="repostaje">
                      {t("flotas.notificaciones.table.alert.repostaje")}
                    </Alert>
                  </td>
                  <td className="facturas-importe">
                    Se ha usado el 90% del saldo previsto para este mes.
                  </td>
                </tr>
                <tr>
                  <td className="facturas-fecha">15/05/21</td>
                  <td className="facturas-periodo">
                    <Alert variant="promocion">
                      {t("flotas.notificaciones.table.alert.promocion")}
                    </Alert>
                  </td>
                  <td className="facturas-importe">
                    Se ha usado el 90% del saldo previsto para este mes.
                  </td>
                </tr>
                <tr>
                  <td className="facturas-fecha">15/05/21</td>
                  <td className="facturas-periodo">
                    <Alert variant="credito">
                      {t("flotas.notificaciones.table.alert.credito")}
                    </Alert>
                  </td>
                  <td className="facturas-importe">
                    Se ha usado el 90% del saldo previsto para este mes.
                  </td>
                </tr>
                <tr>
                  <td className="facturas-fecha">15/05/21</td>
                  <td className="facturas-periodo">
                    <Alert variant="incidencia">
                      {t("flotas.notificaciones.table.alert.incidencia")}
                    </Alert>
                  </td>
                  <td className="facturas-importe">
                    Se ha usado el 90% del saldo previsto para este mes.
                  </td>
                </tr>
                <tr>
                  <td className="facturas-fecha">15/05/21</td>
                  <td className="facturas-periodo">
                    <Alert variant="antifraude">
                      {t("flotas.notificaciones.table.alert.antifraude")}
                    </Alert>
                  </td>
                  <td className="facturas-importe">
                    Se ha usado el 90% del saldo previsto para este mes.
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Container>
      </Container>
    </>
  );
};
