import { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axiosApi from "../../axios/axios-api";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_ESTADO_VEHICULO_FLOTAS = "flotas/estadovehiculo/";
const URL_PUT_REACTIVAR_VEHICULO_FLOTAS = "flotas/reactivarvehiculo/";

export function ModalReactivarVehiculo(props) {
  const { vehiculo } = props;
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const normalizarMatricula = (mat) => {
    if (!mat) return "";

    let matriculaNormalizada = mat?.split("-");
    matriculaNormalizada =
      matriculaNormalizada[matriculaNormalizada.length - 1];

    return matriculaNormalizada;
  };

  const reactivateVehicle = async () => {
    setLoading(true);

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(
          `${URL_GET_ESTADO_VEHICULO_FLOTAS}${vehiculo.idTc}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          let data = {
            idCcp: flotaActiva.id_cuenta,
          };

          try {
            const responseReactivate = await axiosApi.put(
              `${URL_PUT_REACTIVAR_VEHICULO_FLOTAS}${vehiculo.idTar}`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            if (responseReactivate?.data?.code === 200) {
              props.onHide(false, true);
              toast(responseReactivate?.data?.message, {
                icon: <LogoPetroprix />,
              });
            } else {
              toast(responseReactivate?.data?.message, {
                icon: <LogoPetroprix />,
              });
            }
          } catch (error) {
            console.error(error);
          }
        } else if (response?.data?.code === 201) {
          props.onHide(true, false);
        } else {
          props.onHide(false, false);
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">
            {t("flotas.modal.reactivar.pregunta.1")}
            {normalizarMatricula(vehiculo?.matricula)}
            {t("flotas.modal.reactivar.pregunta.2")}
          </h3>
          <div className="d-grid gap-2  mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => props.onHide(false, false)}
            >
              {t("flotas.modal.reactivar.btn-cancel")}
            </Button>
            <Button variant="primary" size="lg" onClick={reactivateVehicle}>
              {t("flotas.modal.reactivar.btn-confirmar")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
