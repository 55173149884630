import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { changeKmFlota } from "../../store/auth";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_PUT_CONTROL_KM = "flotas/kilometros/";

export function ModalControlKM(props) {
  const { show, onHide, controlkm, onChange } = props;
  const { t } = useTranslation();
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const changeControlKM = async () => {
    let km = false;
    if (controlkm) {
      km = false;
    } else {
      km = true;
    }

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(
          `${URL_PUT_CONTROL_KM}${flotaActiva.id_cuenta}`,
          {
            km: km ? 1 : 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
          dispatch(changeKmFlota(km));
          onChange(km);
          onHide();
        } else {
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
          onHide();
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      onHide();
    }
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => onHide(controlkm)}
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">
            {controlkm
              ? t("flotas.modal.control-km.h3.desactivar")
              : t("flotas.modal.control-km.h3.activar")}
          </h3>
          <div className="d-grid gap-2  mt-2">
            <p>{t("flotas.modal.control-km.p")}</p>
          </div>
          <div className="d-grid gap-2  mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => {
                if (controlkm) {
                  onChange(true);
                } else {
                  onChange(false);
                }
                onHide();
              }}
            >
              {t("flotas.modal.control-km.btn-cancel")}
            </Button>
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                changeControlKM();
                onHide();
              }}
            >
              {controlkm
                ? t("flotas.modal.control-km.btn-desactivar")
                : t("flotas.modal.control-km.btn-activar")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
