import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Nav,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import "./estadisticas.css";
import { GraficaSection } from "./GraficaSection";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const EstadisticasScreen = () => {
  const matriculas = ["5757HHH", "1234ABC"];
  const vehiculos = ["Motocicletas", "Turismo", "Furgoneta"];

  const { t } = useTranslation();
  const { token, nombre, apellido } = useSelector((state) => state.auth);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col xs={9} sm={9} lg={{ span: 4, order: 1 }}>
              <h1>{t("estadisticas.header.h1")}</h1>
            </Col>
            <Col
              xs={3}
              sm={3}
              lg={{ span: 1, order: 2 }}
              className="d-block d-md-none"
            >
              <Navbar
                collapseOnSelect
                expand="sm"
                className="estadisticas-filtros"
              >
                <Navbar.Toggle aria-controls="offcanvasNavbar " />
                <Navbar.Offcanvas
                  id="offcanvasNavbarestadisticas"
                  aria-labelledby="offcanvasNavbarLabel"
                  placement="end"
                  className="px-1"
                >
                  <Offcanvas.Header closeButton>
                    <h1>{t("estadisticas.filtros.h1")}</h1>
                  </Offcanvas.Header>
                  <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className="stats-filters-navbar"
                  >
                    <Nav className="me-auto px-3">
                      <Form
                        className="estadisticas-filtros-form"
                        autoComplete="off"
                      >
                        <InputGroup className="estadisticas-filtros-group">
                          <span className="estadisticas-filtros-title">
                            {t(
                              "estadisticas.filtros.form.importe-volumen.title"
                            )}
                          </span>
                          <Form.Check
                            name="importe"
                            id="importe"
                            type="radio"
                            value="0"
                            label={t(
                              "estadisticas.filtros.form.importe-volumen.importe"
                            )}
                          />
                          <Form.Check
                            name="importe"
                            id="volumen"
                            type="radio"
                            value="1"
                            label={t(
                              "estadisticas.filtros.form.importe-volumen.volumen"
                            )}
                          />
                        </InputGroup>
                        <InputGroup className="estadisticas-filtros-group mt-3">
                          <span className="estadisticas-filtros-title">
                            {t("estadisticas.filtros.form.combustibles.title")}
                          </span>
                          <Form.Check
                            type="checkbox"
                            id="Suma total"
                            value="0"
                            label={t(
                              "estadisticas.filtros.form.combustibles.suma"
                            )}
                          />
                          <Form.Check
                            type="checkbox"
                            id="Diésel"
                            value="1"
                            label={t(
                              "estadisticas.filtros.form.combustibles.diesel"
                            )}
                          />
                          <Form.Check
                            type="checkbox"
                            id="Gasolina"
                            value="2"
                            label={t(
                              "estadisticas.filtros.form.combustibles.gasolina"
                            )}
                          />
                        </InputGroup>
                        <InputGroup className="estadisticas-filtros-group mt-3">
                          <span className="estadisticas-filtros-title">
                            {t("estadisticas.filtros.form.matricula.title")}
                          </span>
                          <Form.Check
                            type="checkbox"
                            id="5757HHH"
                            value="5757HHH"
                            label="5757HHH"
                          />
                          <Form.Check
                            type="checkbox"
                            id="4798JFG"
                            value="4798JFG"
                            label="4798JFG"
                          />
                          <Form.Check
                            type="checkbox"
                            id="0044BCJ"
                            value="0044BCJ"
                            label="0044BCJ"
                          />
                        </InputGroup>
                        <InputGroup className="estadisticas-filtros-group mt-3">
                          <span className="estadisticas-filtros-title">
                            {t("estadisticas.filtros.form.anio.title")}
                          </span>
                          <Form.Check
                            name="anio"
                            id="2022"
                            type="radio"
                            value="2022"
                            label="2022"
                          />
                          <Form.Check
                            name="anio"
                            id="2021"
                            type="radio"
                            value="2021"
                            label="2021"
                          />
                          <Form.Check
                            name="anio"
                            id="2020"
                            type="radio"
                            value="2020"
                            label="2020"
                          />
                        </InputGroup>
                        <InputGroup className="estadisticas-filtros-group-btn mt-3">
                          <Button variant="primary" size="lg">
                            {t("estadisticas.filtros.form.guardar-btn")}
                          </Button>
                        </InputGroup>
                      </Form>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar.Offcanvas>
              </Navbar>
            </Col>
            <Col
              className="stats-select-filters d-none d-lg-block"
              lg={{ span: 8, order: 2 }}
            >
              {/* <Filtrosestadisticas matriculas={matriculas} vehiculos={vehiculos} /> */}
            </Col>
          </Row>
        </Container>
        <Container className="mt-2">
          <Row className="content-section">
            <GraficaSection />
          </Row>
        </Container>
      </Container>
    </>
  );
};
