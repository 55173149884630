import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import warning_icon from "../../../assets/images/warning-icon.svg";
import pdf_icon from "../../../assets/images/pdf-icon.svg";
import { useTranslation } from "react-i18next";

export const FlotaStepFour = ({
  prevStep,
  nextStep,
  handleFormData,
  values,
}) => {
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to step 2
    if (
      document.getElementById("nombre").disabled === false ||
      document.getElementById("dni").disabled === false ||
      document.getElementById("tarjeta").disabled === false ||
      document.getElementById("titular").disabled === false
    ) {
      setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <>
      <h1 className="flotas-registro">
        {t("flotas.registro-flota.step-4.h1")}
      </h1>
      <Form
        className="flotas-registro-nueva mt-4 animate__animated animate__fadeIn"
        autoComplete="off"
        onSubmit={submitFormData}
      >
        <Row>
          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-4.form.nombre")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="nombre"
                placeholder={t("flotas.registro-flota.step-4.form.nombre")}
                value={values.nombre}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-4.form.dni")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="dni"
                placeholder={t("flotas.registro-flota.step-4.form.dni")}
                value={values.dni}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-4.form.numero-tarjeta")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="tarjeta"
                placeholder={t(
                  "flotas.registro-flota.step-4.form.numero-tarjeta"
                )}
                value={values.numeroTarjeta}
                disabled
              />
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-flota.step-4.form.titular-tarjeta")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                id="titular"
                placeholder={t(
                  "flotas.registro-flota.step-4.form.titular-tarjeta"
                )}
                value={values.titular}
                disabled
              />
            </FloatingLabel>
          </Col>
        </Row>

        {(values.tipoPago === "Crédito" || values.tipoPago === "Credit") &&
          values.cantidad > 3500 && (
            <>
              <Row>
                <Col xs={3} md={1}>
                  <span className="flotas-warning-icon">
                    <img src={warning_icon} alt="" />
                  </span>
                </Col>
                <Col xs={9} md={9}>
                  <p>
                    {t("flotas.registro-flota.step-4.form.credito-info.p1")}
                    <b>
                      {t(
                        "flotas.registro-flota.step-4.form.credito-info.p1.black"
                      )}
                    </b>
                    {t("flotas.registro-flota.step-4.form.credito-info.p2")}
                    <b>
                      {t(
                        "flotas.registro-flota.step-4.form.credito-info.p2.black"
                      )}
                    </b>
                  </p>
                </Col>
                <Col xs={12} md={2} className="flotas-model-download">
                  <span className="flotas-pdf-icon">
                    <img src={pdf_icon} alt="" />
                  </span>
                  <p>
                    {t(
                      "flotas.registro-flota.step-4.form.credito-info.download"
                    )}
                  </p>
                </Col>
              </Row>
            </>
          )}

        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <Row>
            <div className="flotas-registro-buttons mt-3">
              <Button
                variant="outline-primary"
                size="lg"
                name="resumen"
                onClick={prevStep}
              >
                {t("flotas.registro-flota.btn-back")}
              </Button>
              <Button variant="primary" size="lg" type="submit">
                {t("flotas.registro-flota.btn-next")}
              </Button>
            </div>
          </Row>
        </Col>
      </Form>
    </>
  );
};
