import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  tension: 0.4,
  responsive: true,
  fill: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [125, 180, 360, 281, 82, 90, 128, 600],
      borderColor: "#db0b27",
      backgroundColor: "hsla(352, 90%, 45%, 0.50)",
      fill: {
        target: "origin",
        above: "rgba(238, 75, 75,0.2)", // Area will be red above the origin
        below: "rgba(238, 75, 75, 0.2)", // And blue below the origin
      },
    },
    {
      label: "Dataset 2",
      data: [205, 410, 80, 128, 212, 188, 301],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      fill: {
        target: "origin",
        above: "rgba(252, 165, 52, 0.2)", // Area will be red above the origin
        below: "rgb(252, 165, 52)", // And blue below the origin
      },
    },
    {
      label: "Dataset 3",
      data: [300, 600, 150, 288, 300, 122, 166],
      borderColor: "rgb(46, 204, 113)",
      backgroundColor: "rgba(46, 204, 113, 0.5)",
      fill: {
        target: "origin",
        above: "rgba(46, 204, 113, 0.2)", // Area will be red above the origin
        below: "rgb(46, 204, 113)", // And blue below the origin
      },
    },
  ],
};

export const GraficaSection = () => {
  return (
    <>
      <Line options={options} data={data} width={100} height={120} />;
    </>
  );
};
