export const downloadPdf = (archivoBase64, nombre, tipo) => {
  // Decodificar la cadena Base64 a un blob
  const byteCharacters = atob(archivoBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Crear un objeto URL para el blob
  const url = URL.createObjectURL(blob);

  // Crear un enlace de descarga y hacer clic en él
  const enlaceDescarga = document.createElement("a");
  enlaceDescarga.href = url;
  enlaceDescarga.download = tipo + "_" + nombre + ".pdf"; // Nombre del archivo que se descargará
  document.body.appendChild(enlaceDescarga);
  enlaceDescarga.click();

  // Limpiar después de la descarga
  document.body.removeChild(enlaceDescarga);
  URL.revokeObjectURL(url);
};
