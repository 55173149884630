import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import edit_icon from "../assets/images/edit-icon.svg";
import delete_icon from "../assets/images/delete-icon.svg";
import add_flota_icon from "../assets/images/add-flota-icon.svg";

export const useStepsData = () => {
  const { flotas } = useSelector((state) => state.auth);

  const { t } = useTranslation();

  const locale = {
    back: t("tutorial.back"),
    close: t("tutorial.close"),
    last: t("tutorial.last"),
    next: t("tutorial.next"),
    open: t("tutorial.open"),
    skip: t("tutorial.skip"),
  };

  const steps = [
    {
      target: "nav.d-none",
      content: (
        <>
          {t("tutorial.content.step0.1")}
          <strong>{t("tutorial.content.step0.2")} </strong>
          {t("tutorial.content.step0.3")}
        </>
      ),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step0"),
      disableBeacon: true,
    },
    {
      target: ".step1",
      content: (
        <>
          <p>{t("tutorial.content.step1")}</p>
          {flotas === "1" && <p>{t("tutorial.content.step1.flotas")}</p>}
        </>
      ),
      locale: locale,
      placement: "left",
      title: t("tutorial.title.step1"),
      disableBeacon: true,
    },
    {
      target: ".step2",
      content: (
        <>
          <p>{t("tutorial.content.step2.1")}</p>
          <div style={{ textAlign: "left" }}>
            <p className="mb-2">
              <img
                src={edit_icon}
                alt="edit-icon"
                className="me-3"
                style={{ height: "23px", width: "23px" }}
              />
              {t("tutorial.content.step2.2")}
            </p>
            <p className="mb-2">
              <img
                src={delete_icon}
                alt="delete-icon"
                className="me-3"
                style={{ witdh: "20px", height: "31px" }}
              />
              {t("tutorial.content.step2.3")}
            </p>
            {flotas === "1" && (
              <p className="mb-2">
                <img
                  src={add_flota_icon}
                  alt="add-flota-icon"
                  className="me-3"
                  style={{ witdh: "33px", height: "26px" }}
                />
                {t("tutorial.content.step2.4")}
              </p>
            )}
          </div>
        </>
      ),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step2"),
      disableBeacon: true,
    },
    {
      target: ".step3",
      content: (
        <>
          <p>{t("tutorial.content.step3.1")}</p>
          <p>{t("tutorial.content.step3.2")}</p>
        </>
      ),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step3"),
      disableBeacon: true,
    },
    {
      target: ".step4",
      content: t("tutorial.content.step4"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step4"),
      disableBeacon: true,
    },
    flotas === "1" && {
      target: ".step5",
      content: t("tutorial.content.step5"),
      locale: locale,
      placement: "left",
      title: t("tutorial.title.step5"),
      disableBeacon: true,
    },
    {
      target: ".step6",
      content: t("tutorial.content.step6"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step6"),
      disableBeacon: true,
    },
    {
      target: ".step7",
      content: t("tutorial.content.step7"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step7"),
      disableBeacon: true,
    },
    {
      target: ".step8",
      content: t("tutorial.content.step8"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step8"),
      disableBeacon: true,
    },
    {
      target: ".step9",
      content: t("tutorial.content.step9"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step9"),
      disableBeacon: true,
    },
    {
      target: ".step10",
      content: t("tutorial.content.step10"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step10"),
      disableBeacon: true,
    },
    {
      target: ".step11",
      content: t("tutorial.content.step11"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step11"),
      disableBeacon: true,
    },
    flotas === "1" && {
      target: ".step12",
      content: t("tutorial.content.step12"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step12"),
      disableBeacon: true,
    },
    flotas === "1" && {
      target: ".step13",
      content: t("tutorial.content.step13"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step13"),
      disableBeacon: true,
    },
    flotas === "1" && {
      target: ".step14",
      content: t("tutorial.content.step14"),
      locale: locale,
      placement: "auto",
      title: t("tutorial.title.step14"),
      disableBeacon: true,
    },
  ];

  // Remove null values
  return steps.filter((step) => step);

  return steps;
};

export default useStepsData;
