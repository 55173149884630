import { AppRouter } from "./routers/AppRouter";
import i18n from "./i18n";
import ReactJoyride, { EVENTS } from "react-joyride";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  completedTutorial,
  nextStepTourUser,
  prevStepTourUser,
  resumeTourUser,
  startTourUser,
} from "./store/auth";
import { useStepsData } from "./hooks";

export const PortalClientes = () => {
  // const [language, setLanguage] = useState('en')

  // i18n.changeLanguage(language)

  // console.log(i18n.language)
  const { flotas, tourStatus, tourCurrentStep, token } = useSelector(
    (state) => state.auth
  );
  const tourStatusRef = useRef(tourStatus); // Ref para mantener el valor anterior

  const { t } = useTranslation();
  const [tutorial2Passed, setTutorial2Passed] = useState(
    tourStatus === "finished"
  );
  const [forceRender, setForceRender] = useState(false);

  const navigate = useNavigate();
  const steps = useStepsData();

  const dispatch = useDispatch();

  const handleCallback = (data) => {
    const { status, type, index, lifecycle, action, controlled } = data;

    const finishedStatuses = ["finished"];
    const events = [EVENTS.TARGET_NOT_FOUND];

    if (events.includes(type)) {
      if (action === "next") {
        dispatch(nextStepTourUser());
        switch (index) {
          case 4:
            navigate("/facturas");
            break;
          case 7:
            navigate("/repostajes");
            break;
          case 10:
            navigate("/promociones");
            break;
          case 11:
            navigate("/flotas/mis-flotas");
            break;
          default:
            break;
        }
      } else if (action === "prev") {
        dispatch(prevStepTourUser());
        switch (index) {
          case 5:
            navigate("/datos");
            break;
          case 8:
            navigate("/facturas");
            break;
          case 11:
            navigate("/repostajes");
            break;
          case 12:
            navigate("/promociones");
            break;
          default:
            break;
        }
      }
    } else if (finishedStatuses.includes(status)) {
      dispatch(completedTutorial(token));
      // window.localStorage.setItem("tutorial2Passed", tru e);
    } else if (action === "skip" || action === "close") {
      setTutorial2Passed(true);
      dispatch(completedTutorial(token));
    } else if (action === "next" && lifecycle === "ready") {
      if (index === 1) {
        //Abrimos el menú
        document.querySelector(".step1").click();
      }
    } else if (action === "next" && lifecycle === "complete") {
      dispatch(nextStepTourUser());
      switch (index) {
        case 4:
          navigate("/facturas");
          break;
        case 7:
          navigate("/repostajes");
          break;
        case 10:
          navigate("/promociones");
          break;
        case 11:
          navigate("/flotas/mis-flotas");
          break;
        default:
          break;
      }
    } else if (action === "prev" && lifecycle === "complete") {
      if (tourCurrentStep !== 0) {
        dispatch(prevStepTourUser());
      } else {
        dispatch(startTourUser());
      }

      switch (index) {
        case 5:
          navigate("/datos");
          break;
        case 8:
          navigate("/facturas");
          break;
        case 11:
          navigate("/repostajes");
          break;
        case 12:
          navigate("/promociones");
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (tourStatus !== null && tourStatusRef.current !== tourStatus) {
      setTimeout(() => {
        setForceRender(!forceRender);
      }, 1000);
    }
  }, [tourStatus]);

  return (
    <>
      {tourStatus && tourStatus !== "finished" && !tutorial2Passed && (
        <ReactJoyride
          steps={steps}
          disableOverlayClose={true}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          spotlightClicks={true}
          run={true}
          callback={handleCallback}
          stepIndex={tourCurrentStep}
          controlled={true}
        />
      )}
      <AppRouter />
    </>
  );
};
