import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function ModalInfoVerify(props) {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-3">
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="verify-modal-title"
          >
            {t("verify.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("verify.modal.text.info")}</p>
          <ol>
            <li className="mt-3">{t("verify.modal.list.1")}</li>
            <li className="mt-3">{t("verify.modal.list.2")}</li>
            <li className="mt-3">{t("verify.modal.list.3")}</li>
          </ol>
          {/* <div className="d-grid gap-2 mt-5">
            <Button variant="primary" size="lg" onClick={props.onHide}>
              {" "}
              {t("promos.modal.btn-ok")}
            </Button>
          </div> */}
        </Modal.Body>
      </Container>
    </Modal>
  );
}
