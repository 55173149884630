import React, { useEffect, useState } from "react";
import { Form, Button, Col, FloatingLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import axiosApi from "../../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../../ui/LogoPetroprix";
import { useNavigate } from "react-router-dom";
import { ValidateToken } from "../../../helpers/validateToken";
import { startLogout } from "../../../store/auth";

const URL_CREATE_VEHICULOS_FLOTA = "flotas/crearVehiculo";

export const StepTwo = ({ handleFormData, prevStep, values }) => {
  const [validated, setValidated] = useState(false);
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // after form submit validating the form data using validator
  const submitFormData = async (e) => {
    e.preventDefault();
    setValidated(false);

    // Cambiamos el texto del botón y mostramos el componente miniLoader
    let btn = document.getElementById(
      "flotas-registro-vehiculo-step-2-form-submit"
    );
    btn.disabled = true;

    const impMaxRepost = document.getElementById(
      "flotas-registro-vehiculo-impMaxRepost"
    );
    const impMaxRepostPetroprix = document.getElementById(
      "flotas-registro-vehiculo-impMaxRepostPetroprix"
    );
    const impMaxDiaGas = document.getElementById(
      "flotas-registro-vehiculo-impMaxDiaGas"
    );
    const numRepostDiaPetroprix = document.getElementById(
      "flotas-registro-vehiculo-numRepostDiaPetroprix"
    );
    const numRepostDiaGas = document.getElementById(
      "flotas-registro-vehiculo-numRepostDiaGas"
    );
    const numRepostDia30Min = document.getElementById(
      "flotas-registro-vehiculo-numRepostDia30Min"
    );

    let dataValid = {
      impMaxRepost: false,
      impMaxRepostPetroprix: false,
      impMaxDiaGas: false,
      numRepostDiaPetroprix: false,
      numRepostDiaGas: false,
      numRepostDia30Min: false,
    };

    if (impMaxRepost.value === "") {
      impMaxRepost.setCustomValidity(" ");
      dataValid.impMaxRepost = false;
    } else {
      impMaxRepost.setCustomValidity("");
      dataValid.impMaxRepost = true;
    }

    if (impMaxRepostPetroprix.value === "") {
      impMaxRepostPetroprix.setCustomValidity(" ");
      dataValid.impMaxRepostPetroprix = false;
    } else {
      impMaxRepostPetroprix.setCustomValidity("");
      dataValid.impMaxRepostPetroprix = true;
    }

    if (impMaxDiaGas.value === "") {
      impMaxDiaGas.setCustomValidity(" ");
      dataValid.impMaxDiaGas = false;
    } else {
      impMaxDiaGas.setCustomValidity("");
      dataValid.impMaxDiaGas = true;
    }

    if (numRepostDiaPetroprix.value === "") {
      numRepostDiaPetroprix.setCustomValidity(" ");
      dataValid.numRepostDiaPetroprix = false;
    } else {
      numRepostDiaPetroprix.setCustomValidity("");
      dataValid.numRepostDiaPetroprix = true;
    }

    if (numRepostDiaGas.value === "") {
      numRepostDiaGas.setCustomValidity(" ");
      dataValid.numRepostDiaGas = false;
    } else {
      numRepostDiaGas.setCustomValidity("");
      dataValid.numRepostDiaGas = true;
    }

    if (numRepostDia30Min.value === "") {
      numRepostDia30Min.setCustomValidity(" ");
      dataValid.numRepostDia30Min = false;
    } else {
      numRepostDia30Min.setCustomValidity("");
      dataValid.numRepostDia30Min = true;
    }

    if (
      !dataValid.impMaxRepost ||
      !dataValid.impMaxRepostPetroprix ||
      !dataValid.impMaxDiaGas ||
      !dataValid.numRepostDiaPetroprix ||
      !dataValid.numRepostDiaGas ||
      !dataValid.numRepostDia30Min
    ) {
      setValidated(true);
      return;
    } else {
      values.impMaxRepost = impMaxRepost.value;
      values.impMaxDiaPetroprix = impMaxRepostPetroprix.value;
      values.impMaxDiaGas = impMaxDiaGas.value;
      values.numRepostDiaPetroprix = numRepostDiaPetroprix.value;
      values.numRepostDiaGas = numRepostDiaGas.value;
      values.numRepostDia30Min = numRepostDia30Min.value;

      setValidated(true);

      // Creamos el objeto con los datos del vehículo
      const data = {
        idCcp: values.flotaID,
        matricula: values.matricula,
        factura: values.factura,
        tipo_vehiculo: values.tipoVehiculo,
        tipo_combustible: values.combustible,
        credito_definido:
          values.limiteCredito !== "-1"
            ? values.cantidadLimite
            : values.limiteCredito,
        max_operacion: values.impMaxRepost,
        max_imp_red: values.impMaxDiaPetroprix,
        max_imp_gas: values.impMaxDiaGas,
        max_op_red: values.numRepostDiaPetroprix,
        max_op_gas: values.numRepostDiaGas,
        max_op_30: values.numRepostDia30Min,
        pin: values.pinTxt,
        repetir_pin: values.pinTxtRepeat,
        descripcion: values.descripcion,
      };

      try {
        if (process.env.REACT_APP_URL_API && ValidateToken()) {
          const response = await axiosApi.post(
            URL_CREATE_VEHICULOS_FLOTA,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          if (response?.data?.code === 201) {
            toast(t("datos.vehiculos.nuevo.form.result.ok"), {
              icon: <LogoPetroprix />,
            });
            btn.disabled = false;
            if (ValidateToken()) {
              navigate("/flotas/mis-flotas");
            } else {
              dispatch(startLogout("expired"));
            }
          } else {
            toast(response.data.message, {
              icon: <LogoPetroprix />,
            });
            btn.disabled = false;
          }
        } else {
          dispatch(startLogout("expired"));
        }
      } catch (error) {
        btn.disabled = false;
        console.error(error);
      }
    }
  };

  return (
    <>
      <Form
        className="datos-nuevo-vehiculo"
        autoComplete="off"
        onSubmit={(e) => submitFormData(e)}
        noValidate
        validated={validated}
      >
        <Row>
          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-2.form.imp-max-repost")}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-impMaxRepost"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-repost"
                )}
                defaultValue={values.impMaxRepost}
              />
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-repost.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t(
                "flotas.registro-vehiculo.step-2.form.imp-max-repost-petroprix"
              )}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-impMaxRepostPetroprix"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-repost-petroprix"
                )}
                defaultValue={values.impMaxDiaPetroprix}
              />
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-repost-petroprix.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-2.form.imp-max-dia-gas")}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-impMaxDiaGas"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-dia-gas"
                )}
                defaultValue={values.impMaxDiaGas}
              />
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-2.form.imp-max-dia-gas.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t(
                "flotas.registro-vehiculo.step-2.form.num-repost-dia-petroprix"
              )}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-numRepostDiaPetroprix"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.num-repost-dia-petroprix"
                )}
                defaultValue={values.numRepostDiaPetroprix}
              />
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-2.form.num-repost-dia-petroprix.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t(
                "flotas.registro-vehiculo.step-2.form.num-repost-dia-gas"
              )}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-numRepostDiaGas"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.num-repost-dia-gas"
                )}
                defaultValue={values.numRepostDiaGas}
              />
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-2.form.num-repost-dia-gas")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t(
                "flotas.registro-vehiculo.step-2.form.num-repost-dia-30min"
              )}
              className="mb-3"
            >
              <Form.Control
                type="number"
                min="0"
                id="flotas-registro-vehiculo-numRepostDia30Min"
                placeholder={t(
                  "flotas.registro-vehiculo.step-2.form.num-repost-dia-30min"
                )}
                defaultValue={values.numRepostDia30Min}
              />
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-2.form.num-repost-dia-30min")}
              </Feedback>
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <div className="d-grid gap-2 mt-5">
            <Button
              variant="primary"
              size="lg"
              type="submit"
              id="flotas-registro-vehiculo-step-2-form-submit"
              onClick={(e) => submitFormData(e)}
            >
              {t("flotas.registro-vehiculo.step-2.form.add-next")}
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};
