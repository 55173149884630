import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: "not-authenticated", // 'not-authenticated' | 'authenticated' | 'checking'
    username: null,
    token: null,
    nombre: null,
    apellido: null,
    nif: null,
    flotas: null,
    cuentas: null,
    flotaActiva: null,
    controlKmFlotaActiva: null,
    error: null,
    tourCurrentStep: 0,
    tourStatus: "not-started", // 'not-started' | 'started' | 'finished' | 'paused'
  },
  reducers: {
    login: (state, action) => {
      state.status = "authenticated";
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.nombre = action.payload.nombre;
      state.apellido = action.payload.apellido;
      state.nif = action.payload.nif;
      state.flotas = action.payload.flotas;
      state.cuentas = action.payload.cuentas_creditprix;
      state.flotaActiva = action.payload.flotaActiva;
      state.controlKmFlotaActiva = action.payload.flotaActiva?.km;
      state.error = null;
      state.tutorial = action.payload.tutorial;
      state.tourStatus =
        action.payload.tutorial === "1" ? "finished" : "started";
      state.tourCurrentStep = 0;
    },
    logout: (state, action) => {
      state.status = "not-authenticated";
      state.username = null;
      state.token = null;
      state.nombre = null;
      state.apellido = null;
      state.nif = null;
      state.flotas = null;
      state.cuentas = null;
      state.flotaActiva = null;
      state.controlKmFlotaActiva = null;
      state.error = action.payload.error;
      state.tutorial = null;
      state.tourStatus = null;
      state.tourCurrentStep = null;
    },
    checkingCredentials: (state, action) => {
      state.status = "checking";
      state.error = null;
    },
    changeFlotaActiva: (state, action) => {
      state.flotaActiva = action.payload;
      state.controlKmFlotaActiva = action.payload.km;
    },
    changeCuentas: (state, action) => {
      state.cuentas = action.payload;
    },
    changeKm: (state, action) => {
      state.controlKmFlotaActiva = action.payload === true ? 1 : 0;
    },
    startTour: (state) => {
      state.tourStatus = "started";
      state.tourCurrentStep = 0;
    },
    finishTour: (state) => {
      state.tourStatus = "finished";
      state.tutorial = "1";
    },
    nextStep: (state) => {
      state.tourStatus = "started";
      state.tourCurrentStep++;
    },
    previousStep: (state) => {
      state.tourStatus = "started";
      state.tourCurrentStep--;
    },
    resetTour: (state) => {
      state.tourStatus = "not-started";
      state.tourCurrentStep = 1;
    },
    pauseTour: (state) => {
      state.tourStatus = "paused";
    },
    resumeTour: (state) => {
      if (state.tutorial !== "1") {
        state.tourStatus = "started";
      }
    },
    updateDataClient: (state, action) => {
      state.nombre = action.payload.nombre;
      state.apellido = action.payload.apellido;
      state.nif = action.payload.nif;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  logout,
  checkingCredentials,
  changeFlotaActiva,
  changeCuentas,
  changeKm,
  startTour,
  finishTour,
  nextStep,
  previousStep,
  resetTour,
  pauseTour,
  resumeTour,
  updateDataClient,
} = authSlice.actions;
