import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { changeKmFlota } from "../../store/auth";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_POST_SOLICITAR_FACTURA = "repostajes/factura";

export function ModalSolicitarFacturas(props) {
  const { show, facturas } = props;
  const { t } = useTranslation();
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const solicitarFacturas = async () => {
    try {
      let ids = facturas.toString();
      ids = ids.split(",");

      //casteamos los ids a numeros
      ids = ids.map((id) => parseInt(id));

      let data = {
        idCcp: flotaActiva?.id_cuenta ?? "",
        id_ventas: ids,
      };

      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(URL_POST_SOLICITAR_FACTURA, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
          props.onHide();
        } else {
          toast(response?.data?.message, {
            icon: <LogoPetroprix />,
          });
          props.onHide();
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      props.onHide();
    }
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => props.onHide()}
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">{t("repostajes.modal-facturas.h1")}</h3>
          <div className="d-grid gap-2  mt-2">
            <p>{t("repostajes.modal-facturas.p")}</p>
          </div>
          <div className="d-grid gap-2  mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => {
                props.onHide();
              }}
            >
              {t("repostajes.modal-facturas.btn-cancelar")}
            </Button>
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                solicitarFacturas();
              }}
            >
              {t("repostajes.modal-facturas.btn-solicitar")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
