// Helper para validar el token si esta caducado o no
// Si no esta caducado devuelve true

// Recogemos la cookie con el token
export const ValidateToken = () => {
  let cookieToCheck = "";

  const cookieToken = document.cookie
    .split("; ")
    .find((row) => row.startsWith("token"))
    ?.split("=")[1];

  cookieToCheck = cookieToken;

  // Buscamos si existen cookies cuyo nombre empiece por petroprix y las recuperamos todas para unirlas a la cookie token
  const cookiesPetroprix = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("petroprix"));

  if (cookiesPetroprix.length > 0) {
    cookiesPetroprix.forEach((cookie) => {
      cookieToCheck += cookie.split("=")[1];
    });
  }

  // Comprobamos que el token no esté caducado
  if (
    cookieToCheck !== "" &&
    cookieToCheck !== undefined &&
    cookieToCheck !== null &&
    cookieToCheck !== false
  ) {
    const payload = JSON.parse(atob(cookieToCheck?.split(".")[1]));
    if (payload.exp < Date.now() / 1000) {
      // Borramos la cookie
      document.cookie =
        "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      // Borramos las cookies petroprix si existen
      if (cookiesPetroprix.length > 0) {
        cookiesPetroprix.forEach((cookie) => {
          document.cookie = `${
            cookie.split("=")[0]
          }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        });
      }

      return false;
    }

    return true;
  }

  return false;
};
