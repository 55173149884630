import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import axiosApi from "../../axios/axios-api";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_LINK_ADD_TARJETA = "paycomet/aniadirTarjeta";

export const NuevaTarjetaScreen = () => {
  const { t } = useTranslation();
  const { token, nombre, apellido } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // const lang = i18n.language.toUpperCase();
  // const url_iframe =
  //   "https://api.paycomet.com/gateway/ifr-bankstore?MERCHANT_MERCHANTCODE=rgf6xc5s&MERCHANT_TERMINAL=22240&OPERATION=107&LANGUAGE=" +
  //   lang +
  //   "&MERCHANT_MERCHANTSIGNATURE=7a91392104a4493e4b9b07eada1e4d27dbdf6e5dbfdf06b7b948a58bb1da0a30b11ae25bee8b0b915a396a95a8ca4f31bb622d98f9fd27eeb755d2e7ef4aa8f2&MERCHANT_ORDER=CRX-197";
  const [loading, setLoading] = useState(false);
  const [urlIframe, setUrlIframe] = useState("");

  const fetchURLLink = useCallback(async () => {
    setLoading(true);

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(
          URL_GET_LINK_ADD_TARJETA,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setUrlIframe(data);
          setLoading(false);
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }, []);

  // Actualizamos el estado de vehiculos cada vez que se añade uno nuevo
  useEffect(() => {
    fetchURLLink();
  }, [fetchURLLink]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <Col xs={12} sm={12} lg={{ span: 9, order: 1 }}>
              <h1>
                <Link to={`/flotas/metodos-pago`} className="no-decoration">
                  &lt;
                </Link>
                {t("pago.nueva-tarjeta.h1")}
              </h1>
            </Col>
          </Row>
        </Container>
        <Container className="py-1">
          <Row className="content-section">
            {!loading && urlIframe != "" ? (
              <iframe
                title="titulo"
                id="iframe_content"
                width="400"
                height="525"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                scrolling="no"
                style={{
                  border: "0px solid #000000",
                  margin: "0px",
                  marginInline: "auto",
                  paddingLeft: "10%",
                }}
                src={urlIframe}
              ></iframe>
            ) : (
              <LoaderPetroprix />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
