import { useState } from "react";
import { Table } from "react-bootstrap";
import { t } from "i18next";
import { Pagination } from "../ui/Pagination";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { format } from "date-fns";

export const TablaHistorialPagos = ({ data, token, orderData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const nPages = Math.ceil(data.length / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  return (
    <>
      <Table hover={false} className="historial-pagos" borderless={true}>
        <thead>
          <tr>
            <th>
              {t("pago.historial.table.cuenta")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 0)} />
                <AiFillCaretUp onClick={orderData(true, 0)} />
              </span>
            </th>
            <th>
              {t("pago.historial.table.cantidad")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 1)} />
                <AiFillCaretUp onClick={orderData(true, 1)} />
              </span>
            </th>
            <th>
              {t("pago.historial.table.concepto")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 2)} />
                <AiFillCaretUp onClick={orderData(true, 2)} />
              </span>
            </th>
            <th>
              {t("pago.historial.table.fecha")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 3)} />
                <AiFillCaretUp onClick={orderData(true, 3)} />
              </span>
            </th>
            <th>
              {t("pago.historial.table.tarjeta")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 4)} />
                <AiFillCaretUp onClick={orderData(true, 4)} />
              </span>
            </th>
            <th>
              {t("pago.historial.table.marca-tarjeta")}
              <span className="order-table-icons float-end">
                <AiFillCaretDown onClick={orderData(false, 5)} />
                <AiFillCaretUp onClick={orderData(true, 5)} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((pago, index) => (
            <tr key={index}>
              <td className="historial-nombre">{pago.cuenta || "-"}</td>
              <td className="historial-cantidad">{pago.cantidad} EUR</td>
              <td className="historial-concepto">{pago.concepto || "-"}</td>
              <td className="historial-fecha">
                {pago.fecha === null ? (
                  "-"
                ) : (
                  <>
                    <span className="historial-fecha-dia">
                      {format(new Date(pago.fecha), "dd/MM/yyyy")}
                    </span>
                    <span className="historial-fecha-hora">
                      {format(new Date(pago.fecha), "HH:mm")}
                    </span>
                  </>
                )}
              </td>
              <td className="historial-tarjeta">{pago.tarjeta || "-"}</td>
              <td className="historial-marca">{pago.marca || "-"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        nPages={nPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
        totalRecords={data.length}
      />
    </>
  );
};
