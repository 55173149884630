import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MiniLoader } from "../MiniLoader";
import { useEffect } from "react";

export const SelectMatriculas = (props) => {
  const { t } = useTranslation();
  const [matriculas, setMatriculas] = useState(props.items);
  const [filtrado, setFiltrado] = useState(matriculas);
  const [loadingVehiculos, setLoadingVehiculos] = useState(
    props.loadingVehicles
  );

  const handleSelect = (e) => {
    let filtroMatriculas = [];
    let matriculas = document.getElementById("filter-matriculas").children;
    for (let i = 0; i < matriculas.length; i++) {
      if (matriculas[i].firstChild.firstChild.checked) {
        filtroMatriculas.push(matriculas[i].firstChild.firstChild.value);
      }
    }
    props.onChange(filtroMatriculas);

    // Cerrar el dropdown al guardar los filtros
    document.getElementById("dropdown-autoclose-inside-matriculas").click();
  };

  useEffect(() => {
    setLoadingVehiculos(props.loadingVehicles);
  }, [props.loadingVehicles]);

  useEffect(() => {
    setMatriculas(props.items);
    setFiltrado(props.actual);
  }, [props.items, props.actual]);

  return (
    <>
      <Dropdown
        autoClose="outside"
        className="filter-secondary filter-matricula"
      >
        {loadingVehiculos && <MiniLoader />}
        {!loadingVehiculos &&
          matriculas.length > 0 &&
          matriculas[0] !== null &&
          matriculas[0] !== undefined && (
            <Dropdown.Toggle
              id="dropdown-autoclose-inside-matriculas"
              className="btn-filter-secondary"
              variant={"filter-secondary"}
            >
              {t("repostajes.filtros.matriculas")}
            </Dropdown.Toggle>
          )}
        {!loadingVehiculos && (
          <Dropdown.Menu>
            <span id="filter-matriculas" className="filter-dropdown">
              {matriculas.map((matricula) => (
                <Form.Group
                  className="dropdown-item mb-0"
                  controlId={"checkbox-filter-matricula-" + matricula}
                  key={"checkbox-filter-matricula-" + matricula}
                >
                  <Form.Check
                    type="checkbox"
                    label={matricula}
                    value={matricula}
                    defaultChecked={filtrado.includes(matricula)}
                  />
                </Form.Group>
              ))}
            </span>

            <Dropdown.Item className="save-filters">
              <Button variant="secondary" onClick={handleSelect}>
                {t("repostajes.filtros.btn-guardar")}
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};
