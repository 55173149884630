import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../ui/Header";
import { Menu } from "../../ui/Menu";
import { SaldoStep } from "./SaldoStep";
import { ResumenStep } from "./ResumenStep";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axiosApi from "../../../axios/axios-api";
import { ValidateToken } from "../../../helpers/validateToken";
import { startLogout } from "../../../store/auth";

export const AgregarSaldoScreen = () => {
  const { nombre, apellido, flotaActiva, token } = useSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ValidateToken()) {
      if (!flotaActiva || flotaActiva.permisos === 0) {
        navigate("/flotas/mis-flotas");
      }
    } else {
      dispatch(startLogout("expired"));
    }
  }, [flotaActiva]);

  useEffect(() => {
    if (flotaActiva.permisos === 0) {
      if (ValidateToken()) {
        navigate("/flotas/mis-flotas");
      } else {
        dispatch(startLogout("expired"));
      }
    }
  }, [flotaActiva]);

  //state for steps
  const [step, setStep] = useState(1);

  const { t } = useTranslation();

  const [saldos, setSaldos] = useState({
    saldoTotal: "-",
    saldoRestante: "-",
    saldoUsado: "-",
  });

  const fetchSaldosFlota = async (id) => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(`flota/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          let data = response.data.data;
          setSaldos({
            saldoTotal: data.Saldo_total,
            saldoRestante: data.Saldo_restante,
            saldoUsado: data.Saldo_usado,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchSaldosFlota(flotaActiva.id_cuenta);
    }
  }, [flotaActiva]);

  //state for form data
  const [formData, setFormData] = useState({
    flota_id: "",
    flota_nombre: "",
    importe: "",
    tarjetaCredito: "",
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  // // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form

    const { value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  // javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellido} />
            <Container>
              <Row className="page-header pb-4">
                <Col sm={12} lg={{ span: 5, order: 1 }}>
                  <h1>
                    <Link to={`/flotas/mis-flotas`} className="no-decoration">
                      &lt;
                    </Link>
                    {t("flotas.agregar-saldo.header.h1")}
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="content-section mb-4">
                <Col xs={12} lg={{ span: 6, offset: 3 }}>
                  <SaldoStep
                    nextStep={nextStep}
                    values={formData}
                    handleFormData={handleInputData}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      );
    // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 2:
      return (
        <>
          <Menu />
          <Container className="content-page">
            <Header name={nombre + " " + apellido} />
            <Container>
              <Row className="page-header pb-4">
                <Col sm={12} lg={{ span: 5, order: 1 }}>
                  <h1>
                    <span onClick={prevStep} className="no-decoration">
                      &lt;
                    </span>
                    {t("flotas.agregar-saldo.header.h1.resumen")}
                  </h1>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="content-section mb-4">
                <ResumenStep
                  prevStep={prevStep}
                  values={formData}
                  handleFormData={handleInputData}
                  saldos={saldos}
                />
              </Row>
            </Container>
          </Container>
        </>
      );
    default:
      return <></>;
  }
};
