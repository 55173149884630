import loader_circle from "../../assets/images/loader-circle-petroprix.svg";
import loader_letter from "../../assets/images/loader-letter-petroprix.svg";
import loader from "../../assets/images/loader.gif";
import "./LoaderPetroprix.css";

export const LoaderPetroprix = ({
  type = "",
  position = "fixed",
  min = "16vh",
}) => {
  return (
    <div className={`loaderPetroprix ${type}`} style={{ minHeight: min }}>
      <img src={loader} alt="loading" width="100" height="100" />
    </div>
  );
};
