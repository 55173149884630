import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FiltrosFacturas } from "./FiltrosFacturas";
import "./facturas.css";
import "../ui/filtros/selects.css";
import { Menu } from "../ui/Menu";
import { Header } from "../ui/Header";
import { useTranslation } from "react-i18next";
import { LoaderPetroprix } from "../ui/LoaderPetroprix";
import { TablaFacturas } from "./TablaFacturas";
import { useDispatch, useSelector } from "react-redux";
import { continueTourUser, nextStepTourUser } from "../../store/auth";
import { format } from "date-fns";

const URL_GET_FACTURAS = "facturas";

export const FacturasScreen = () => {
  const { t } = useTranslation();
  const [facturas, setFacturas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(facturas);
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [filtered, setFiltered] = useState(false);
  const { token, nombre, apellido, flotas } = useSelector(
    (state) => state.auth
  );

  const orderData = (asc, index) => () => {
    let data;
    if (!filtered) {
      data = [...facturas];
    } else {
      data = [...resultadosBusqueda];
    }

    switch (index) {
      case 0:
        index = "fecha";
        break;
      case 1:
        index = "periodo";
        break;
      case 2:
        index = "total";
        break;
      case 3:
        index = "num_factura";
        break;
      default:
        break;
    }

    if (asc) {
      data.sort((a, b) => {
        if (a[index] < b[index]) {
          return -1;
        }
        if (a[index] > b[index]) {
          return 1;
        }
        return 0;
      });
    } else {
      data.sort((a, b) => {
        if (a[index] > b[index]) {
          return -1;
        }
        if (a[index] < b[index]) {
          return 1;
        }
        return 0;
      });
    }
    filtered ? setResultadosBusqueda(data) : setFacturas(data);
  };

  const filtrar = (terminoBusqueda) => {
    let result = facturas.filter((elemento) => {
      if (
        format(new Date(elemento.fecha), "dd/MM/yyyy")
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.total
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase()) ||
        elemento.num_factura
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
      ) {
        return elemento;
      }
    });
    setResultadosBusqueda(result);
    setFiltered(true);
  };

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row
            className={`page-header pb-4 ${flotas === "1" ? "has-flotas" : ""}`}
          >
            <Col sm={12} lg={{ span: 5, order: 1 }}>
              <h1>{t("facturas.header.h1")}</h1>
            </Col>
            <Col sm={12} lg={{ span: 3, order: 3 }}>
              <Form
                className="search"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                autoComplete="off"
              >
                <InputGroup className="mb-2">
                  <InputGroup.Text>
                    <span className="material-icons-outlined">search</span>
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder={t("facturas.header.buscador")}
                    className="no-border-left"
                    id="buscadorFacturas"
                    name="searchText"
                    value={terminoBusqueda}
                    onChange={(e) => {
                      setTerminoBusqueda(e.target.value);
                      filtrar(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputGroup>
              </Form>
            </Col>
            <Col
              sm={12}
              lg={{ span: 4, order: 2 }}
              className="filter-select-filters facturas step7"
            >
              <FiltrosFacturas
                url={URL_GET_FACTURAS}
                token={token}
                facturas={facturas}
                setFacturas={setFacturas}
                loading={loading}
                setLoading={setLoading}
                setTerminoBusqueda={setTerminoBusqueda}
                setFiltered={setFiltered}
              />
            </Col>
          </Row>
        </Container>
        <Container className="mt-5 py-1 step6">
          <Row className="content-section content-has-flotas">
            {!loading ? (
              <>
                {facturas.length > 0 ? (
                  <>
                    <TablaFacturas
                      data={filtered ? resultadosBusqueda : facturas}
                      token={token}
                      orderData={orderData}
                    />
                    <Row>
                      <Col
                        className="d-none d-lg-block"
                        lg={{ span: 2, offset: 10 }}
                      ></Col>
                    </Row>
                  </>
                ) : (
                  <p className="text-center">
                    {t("facturas.form.table.no-results")}
                  </p>
                )}
              </>
            ) : (
              <LoaderPetroprix />
            )}
          </Row>
        </Container>
      </Container>
    </>
  );
};
