import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";

export const SelectAnios = (props) => {
  const [selected, setSelected] = useState(props.actual);

  useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  return (
    <>
      <DropdownButton
        variant={"filter-secondary"}
        title={selected}
        id={"dropdown-basic-button-anios"}
      >
        {props.items.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={() => {
              setSelected(item);
            }}
          >
            <Form.Check
              type={"radio"}
              id={"opt-anios-" + item}
              name={item}
              label={item}
              value={item}
              className="filter-form"
              checked={selected === item}
              readOnly
            />
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
};
