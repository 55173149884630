import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import flota_block_icon from "../../assets/images/flota-block-icon.svg";
import { use } from "i18next";
import { forwardRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { continueTourUser, nextStepTourUser } from "../../store/auth";

export const FlotasSelection = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const flotas = props.flotas;
  const flotaActiva = props.flotaActiva;
  const token = props.token;
  const dispatch = useDispatch();

  const handleSelectFlota = (flota) => {
    props.onChange(flota, token);
  };

  return (
    <>
      {props.flotas ? (
        flotas.length > 1 ? (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              flotaActiva
                ? flotaActiva.nombre_cuenta.toLowerCase()
                : "Cargando..."
            }
            className="flotas-selection"
            ref={ref}
          >
            {flotas.map((flota, index) => {
              return (
                JSON.stringify(flota) !== JSON.stringify(flotaActiva) && (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      handleSelectFlota(flota);
                    }}
                    className="flotas-selection-item"
                  >
                    {flota.nombre_cuenta.toLowerCase()}
                  </Dropdown.Item>
                )
              );
            })}

            {/* <Dropdown.Divider />
          <Dropdown.Item href="/flotas/nueva-flota">
            {t("flotas.info.listado.btn-add-flota")}
          </Dropdown.Item> */}
          </DropdownButton>
        ) : (
          <div className="flotas-selection-div">
            {flotaActiva
              ? flotaActiva.nombre_cuenta.toLowerCase()
              : "Cargando..."}
          </div>
        )
      ) : (
        <></>
      )}
      {flotaActiva.activo === "0" && (
        <div className="flota-bloqueada">
          <img
            src={flota_block_icon}
            alt="flota bloqueada"
            className="flota-bloqueada-icon"
          />
          {t("flotas.alert.flota-bloqueada")}
        </div>
      )}
    </>
  );
});
