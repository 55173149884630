import React from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export function ModalBorrarVehiculo(props) {
  const { id, matricula } = props;
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const deleteVehicle = async () => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.delete(`vehiculos/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          // cerramos el modal
          props.onHide(true);
          toast(t("datos.modal.borrar.result.ok"), {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Body>
          <h3 className="title-modal">
            {t("datos.modal.borrar.h3")} {matricula}?
          </h3>
          <div className="d-grid gap-2  mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={() => props.onHide(false)}
            >
              {t("datos.modal.borrar.cancel-btn")}
            </Button>
            <Button variant="primary" size="lg" onClick={deleteVehicle}>
              {t("datos.modal.borrar.ok-btn")}
            </Button>
          </div>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
